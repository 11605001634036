import { Check, Close } from "@mui/icons-material";
import _ from "lodash";

export const ingredientColumns = [
  {
    id: "category",
    numeric: false,
    label: "Rodzina",
  },
  {
    id: "name",
    numeric: false,
    label: "Nazwa",
  },
  {
    id: "nameEn",
    numeric: false,
    label: "Nazwa łacińska",
  },
  {
    id: "namePl",
    numeric: false,
    label: "Nazwa polska",
  },
];

export const ingredientAdminColumns = [
  {
    id: "code",
    numeric: false,
    label: "Kod",
    width: "10%",
  },
  {
    id: "category",
    numeric: false,
    label: "Rodzina",
  },
  {
    id: "name",
    numeric: false,
    label: "Nazwa",
  },
  {
    id: "nameEn",
    numeric: false,
    label: "Nazwa łacińska",
  },
  {
    id: "namePl",
    numeric: false,
    label: "Nazwa polska",
  },
  {
    id: "inMagazine",
    numeric: true,
    label: "Na magazynie",
    width: "100px",
  },
];

export const savedReceiptIngredientColumns = _.concat(ingredientColumns, [
  {
    id: "count",
    numeric: true,
    label: "Wymagana ilość [g]",
  },
]);

export const receiptIngredientColumns = _.concat(ingredientColumns, [
  {
    id: "count",
    numeric: true,
    label: "Wymagana ilość [g]",
  },
  {
    id: "dose",
    numeric: true,
    label: "Dzienna dawka  [g]",
  },
]);

export const shippingReceiptIngredientColumns = [
  {
    id: "isScanned",
    numeric: true,
    label: "Zeskanowano",
    width: "10%",
    valueGetter: (value) =>
      value ? (
        <span
          style={{
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
          }}
        >
          <Check color="success" fontSize="small" />
        </span>
      ) : (
        <span
          style={{
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
          }}
        >
          <Close color="error" fontSize="small" />
        </span>
      ),
  },
  {
    id: "code",
    numeric: false,
    label: "Kod",
    width: "10%",
  },
  {
    id: "name",
    numeric: false,
    label: "Nazwa",
  },
  {
    id: "count",
    numeric: true,
    label: "Wymagana ilość [g]",
  },
  {
    id: "dose",
    numeric: true,
    label: "Dzienna dawka  [g]",
  },
];
