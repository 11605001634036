import { useEffect, useState } from "react";
import { validate } from "../validators";
import _ from "lodash";

export const useFormState = ({
  initialFormData,
  initialErrorData,
  activeItem,
  fetchData,
}) => {
  const [formData, setFormData] = useState(initialFormData);
  const [errorData, setErrorData] = useState(initialErrorData);

  const resetStates = () => {
    setFormData({ ...initialFormData });
    setErrorData({ ...initialErrorData });
  };

  useEffect(() => {
    return () => {
      resetStates();
    };
  }, []);

  useEffect(() => {
    if (!!activeItem) setFormData({ ...activeItem });
  }, [activeItem]);

  const changeFormData = (e, obj) => {
    const isAutocomplete = e?.target?.id?.split("-")?.[0] === "autocomplete";
    const newFormData = _.cloneDeep(formData);

    if (isAutocomplete) {
      const objName = e.target.id.split("-")[1];
      _.set(newFormData, objName, obj);
    } else {
      _.set(newFormData, e.target.id, e.target.value);
    }

    if (
      (e?.target?.id === "lastName" || e?.target?.id === "firstName") &&
      _.has(newFormData, "fullName")
    ) {
      _.set(
        newFormData,
        "fullName",
        `${newFormData.lastName} ${newFormData.firstName}`
      );
    }

    setFormData({ ...newFormData });
  };

  const onSubmitForm = async (type, url, onSuccess, body, withToken) => {
    setErrorData(initialErrorData);

    const { success, errors } = validate(formData, errorData);
    if (success) {
      const response = await fetchData(type, url, body, withToken);
      if (response.success) onSuccess(response);
      else
        setErrorData((prevState) => ({
          ...prevState,
          form: { value: true, message: response.message },
        }));
    } else {
      setErrorData({ ...errors });
    }
  };

  return {
    formData,
    errorData,
    changeFormData,
    onSubmitForm,
    setFormData,
    resetStates,
    setErrorData,
  };
};
