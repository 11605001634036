import React, { useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Grid, Wrapper } from "../components";
import { onSetCopiedItem } from "../stores/appStore";
import { useLoadingState, useDialogState, useFetchData } from "../utils/hooks";
import { dialogTypes, fetchTypes } from "../utils/enums";
import { Endpoints } from "../utils/endpoints";
import { sentReceiptColumns } from "../utils/columns";

const SentReceipts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const { isDownloading, changeDownloading } = useLoadingState();
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();
  const { fetchData } = useFetchData({ changeDownloading });

  const handleSetShouldRefresh = (value) => {
    setShouldRefresh(!!value);
  };

  const handleCopyReceipt = async (item) => {
    const response = await fetchData(
      fetchTypes.get,
      `${Endpoints.receiptsHistory}${item.id}/`
    );
    if (response.success) {
      dispatch(onSetCopiedItem(response.data));
      setTimeout(() => {
        navigate("/nowa-recepta");
      }, 500);
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  const handleToggleFavoriteReceipt = async (item) => {
    const response = await fetchData(fetchTypes.post, Endpoints.favorites, {
      id: [item.id],
      delete: item.isFavourite,
    });

    if (response.success) {
      handleSetShouldRefresh(true);
      setDialog(
        dialogTypes.info,
        item.isFavourite
          ? `Recepta ${item.barcodeText} została usunięta z ulubionych.`
          : `Recepta ${item.barcodeText} została dodana do ulubionych.`
      );
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  return (
    <Wrapper
      isDownloading={isDownloading}
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={setDialog}
    >
      <Grid
        columns={sentReceiptColumns}
        url={`${Endpoints.receiptsHistory}?status=1`}
        title="Wysłane recepty"
        checking={false}
        expanding={true}
        expandContent="receipt"
        initialOrderBy="dateSent"
        onCopyItem={handleCopyReceipt}
        onFavoriteItem={handleToggleFavoriteReceipt}
        shouldRefresh={shouldRefresh}
        setRefresh={handleSetShouldRefresh}
      />
    </Wrapper>
  );
};

export default SentReceipts;
