import React, { useEffect, useState } from "react";
import { Alert, Box, Button } from "@mui/material";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Wrapper } from "../../components";
import {
  useLoadingState,
  useFetchData,
  useDialogState,
  useOrderManagement,
} from "../../utils/hooks";
import { Endpoints } from "../../utils/endpoints";
import { dialogTypes, fetchTypes } from "../../utils/enums";
import { emptyReceipt } from "./utlis";
import { OrderForm, OrderPricesForm } from "./components";

const NewOrder = () => {
  const clients = useSelector((state) => state.app.clients);
  const products = useSelector((state) => state.app.products);

  const [receipts, setReceipts] = useState([emptyReceipt]);
  const [isCalculated, setIsCalculated] = useState(false);

  const { isDownloading, isWaitingForResponse, changeWaitingForResponse } =
    useLoadingState();
  const { fetchData } = useFetchData({ changeWaitingForResponse });
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();
  const {
    formData,
    errorData,
    priceData,
    errorPriceData,
    clearStates,
    handleChangeForm,
    handleAddProduct,
    handleDeleteProduct,
    handleCalculateOrder,
    handleChangePriceForm,
    handleSubmitReceipt,
  } = useOrderManagement({ setIsCalculated, fetchData, setDialog });

  const clearData = () => {
    setReceipts([emptyReceipt]);
    setIsCalculated(false);
    clearStates();
    setDialog();
  };

  useEffect(() => {
    async function getCreatedReceipts() {
      const response = await fetchData(
        fetchTypes.get,
        `${Endpoints.receiptsHistoryMinified}?status=0`
      );
      if (response.success) {
        setReceipts(_.concat(emptyReceipt, response.data));
      } else {
        setDialog(dialogTypes.error, response.message);
      }
    }

    getCreatedReceipts();
    return () => {
      clearData();
    };
  }, []);

  const onSubmit = async () => {
    function onSuccess() {
      clearData();
      if (!!formData["receipt"].id) {
        setDialog(
          dialogTypes.info,
          "Zamówienie zostało dodane do wskazanej recepty. Należy wysłać receptę, do której dopisano niniejsze zamówienie, w celu wysłania całości."
        );
      } else {
        setDialog(dialogTypes.info, "Zamówienie zostało wysłane.");
      }
    }

    handleSubmitReceipt(fetchTypes.post, Endpoints.ordersHistory, onSuccess);
  };

  const handleClearForm = () => {
    clearData();
  };

  return (
    <Wrapper
      isDownloading={isDownloading}
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleAccept={handleClearForm}
      handleCloseDialog={setDialog}
    >
      <Box display="flex" flexDirection="column" gap="10px">
        <OrderForm
          products={products}
          receipts={receipts}
          isCalculated={isCalculated}
          isWaiting={isWaitingForResponse}
          formData={formData}
          errorData={errorData}
          clients={clients}
          handleChangeForm={handleChangeForm}
          handleAddProduct={handleAddProduct}
          handleDeleteProduct={handleDeleteProduct}
          setDialog={setDialog}
        />

        <OrderPricesForm
          isCalculated={isCalculated}
          isWaiting={isWaitingForResponse}
          priceData={priceData}
          errorPriceData={errorPriceData}
          handleChangePriceForm={handleChangePriceForm}
        />

        {errorPriceData["form"].value && (
          <Alert severity="error">
            {errorPriceData["form"].message || "Podane dane są błędne!"}
          </Alert>
        )}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          padding="16px"
          gap="10px"
        >
          {isCalculated ? (
            <>
              <Button
                onClick={() => setIsCalculated(false)}
                disabled={isWaitingForResponse}
              >
                Edytuj zamówienie
              </Button>
              <Button
                onClick={onSubmit}
                variant="contained"
                disabled={isWaitingForResponse}
              >
                {!!formData["receipt"].id
                  ? "Dodaj do recepty"
                  : "Wyślij zamówienie"}
              </Button>
            </>
          ) : (
            <Button onClick={handleCalculateOrder} variant="contained">
              Przelicz zamówienie
            </Button>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};

export default NewOrder;
