const domain = "https://api.receptydelewinprime.pl/";

export const Endpoints = {
  register: `${domain}auth/register/`,
  login: `${domain}auth/login/`,
  confirmUser: `${domain}auth/confirm/?token=`,
  user: `${domain}auth/user/`,
  passwordReset: `${domain}auth/password/reset/`,
  passwordChange: `${domain}auth/password/change/`,
  passwordResetConfirm: `${domain}auth/password/reset/confirm/`,
  invite: `${domain}auth/invite/`,
  settings: `${domain}settings/`,
  news: `${domain}news/`,
  clients: `${domain}clients/`,
  ingredients: `${domain}ingredients/`,
  receipts: `${domain}receipts/`,
  receiptsMinified: `${domain}receipts/minified/`,
  receiptsHistory: `${domain}receipts-history/`,
  receiptsHistoryMinified: `${domain}receipts-history/minified/`,
  receiptsToScan: `${domain}receipts-history/to-pack/`,
  ordersHistory: `${domain}orders-history/`,
  ordersHistoryMinified: `${domain}orders-history/minified/`,
  clientHistory: `${domain}receipts-history/clients/?client_id=`,
  products: `${domain}products/`,
  commisionReceipts: `${domain}commision-receipts/`,
  commisionOrders: `${domain}commision-orders/`,
  doctors: `${domain}doctors/`,
  ingredientCategories: `${domain}ingredient-categories/`,
  favorites: `${domain}receipts-history/favorites/`,
  sendBatch: `${domain}receipts-history/send-batch/`,
  analytics: `${domain}ingredients/analytics/`,
};
