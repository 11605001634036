import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Table, Wrapper } from "../../components";
import { onDeleteClient } from "../../stores/appStore";
import { getClientLabel } from "../../utils/helpers/app";
import ClientDialog from "./components/ClientDialog";
import {
  useDialogState,
  useFetchData,
  useLoadingState,
} from "../../utils/hooks";
import { clientColumns } from "../../utils/columns";
import { dialogTypes, fetchTypes } from "../../utils/enums";
import { Endpoints } from "../../utils/endpoints";
import { Add } from "@mui/icons-material";

const Clients = () => {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.app.clients);
  const [activeItem, setActiveItem] = useState(null);

  const { isDownloading, changeDownloading } = useLoadingState();
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();
  const { fetchData } = useFetchData({ changeDownloading });

  useEffect(() => {
    return () => {
      setActiveItem(null);
    };
  }, []);

  const getActiveItem = (item) => {
    const { address, ...rest } = item;
    return {
      city: address?.city || "",
      street: address?.street || "",
      zipCode: address?.zipCode || "",
      ...rest,
    };
  };

  const handleCreateClient = () => {
    setActiveItem(null);
    setDialog(dialogTypes.add);
  };

  const handleEditClient = (item) => {
    setActiveItem(getActiveItem(item));
    setDialog(dialogTypes.edit);
  };

  const handleRemoveClient = (item) => {
    setActiveItem(getActiveItem(item));
    setDialog(
      dialogTypes.remove,
      `Czy na pewno chcesz usunąć: ${getClientLabel(item)}?`
    );
  };

  const handleCloseDialog = () => {
    setDialog();
    setActiveItem(null);
  };

  const handleDeleteClient = async () => {
    setDialog();
    const response = await fetchData(
      fetchTypes.delete,
      `${Endpoints.clients}${activeItem.id}/`
    );
    if (response.success) {
      dispatch(onDeleteClient(activeItem.id));
      handleCloseDialog();
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  const ToolbarActions = () => (
    <Button
      variant="contained"
      onClick={handleCreateClient}
      startIcon={<Add />}
    >
      Nowy
    </Button>
  );

  return (
    <Wrapper
      isDownloading={isDownloading}
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={handleCloseDialog}
      handleDelete={handleDeleteClient}
    >
      <ClientDialog
        open={
          isDialogVisible(dialogTypes.add) || isDialogVisible(dialogTypes.edit)
        }
        handleClose={handleCloseDialog}
        activeItem={activeItem}
        setDialog={setDialog}
      />
      <Table
        columns={clientColumns}
        data={clients}
        title="Klienci"
        checking={false}
        expanding={true}
        expandContent="client"
        initialOrder="asc"
        initialOrderBy="lastName"
        toolbarActions={<ToolbarActions />}
        onEditItem={handleEditClient}
        onDeleteItem={handleRemoveClient}
      />
    </Wrapper>
  );
};

export default Clients;
