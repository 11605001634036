import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { FormContainer } from "./form";
import _ from "lodash";

const ExpandTable = ({ title, columns, data }) => {
  const TableItem = ({ title, content }) =>
    !!content && (
      <TableRow hover>
        <TableCell size="small" align="right" width="40%">
          <Typography fontWeight={500} fontSize={12}>
            {`${title}:`}
          </Typography>
        </TableCell>
        <TableCell size="small">
          <Typography fontSize={12} component="div">
            {content}
          </Typography>
        </TableCell>
      </TableRow>
    );

  return (
    <>
      <FormContainer title={title} />
      <Table sx={{ marginBottom: 2 }}>
        <TableBody>
          {_.map(columns, (column, index) => (
            <TableItem
              key={`receiptItem-${column.obj}-${data.id}-${index}`}
              title={column.title}
              content={
                !!column.valueGetter
                  ? column.valueGetter(data?.[column.obj])
                  : data?.[column.obj]
              }
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default ExpandTable;
