import { useEffect, useState } from "react";
import _ from "lodash";

export const useTableManagement = ({
  data = [],
  columns,
  initialOrder = "desc",
  initialOrderBy,
}) => {
  const [rows, setRows] = useState(data);
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [selected, setSelected] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (searchValue)
      setRows(
        _.filter(data, (row) =>
          _.some(columns, (column) =>
            _.includes(
              _.lowerCase(_.toString(row[column.id])),
              _.lowerCase(_.toString(searchValue))
            )
          )
        )
      );
    else setRows(data);
  }, [data, searchValue]);

  useEffect(() => {
    return () => {
      setRows(data);
      setSearchValue("");
      setOrder(initialOrder);
      setOrderBy(initialOrderBy);
      setSelected([]);
      setSelectedIds([]);
      setExpanded([]);
      setPage(0);
      setRowsPerPage(10);
    };
  }, []);

  const handleChangeSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = rows.map((n) => n.id);
      setSelected(rows);
      setSelectedIds(newSelectedIds);
      return;
    }
    setSelected([]);
    setSelectedIds([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelectedIds = [];

    if (selectedIndex === -1) {
      newSelectedIds = newSelectedIds.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelectedIds = newSelectedIds.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelectedIds = newSelectedIds.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedIds = newSelectedIds.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }

    setSelected(
      _.map(newSelectedIds, (newSelectedId) =>
        _.find(rows, (row) => row.id === newSelectedId)
      )
    );
    setSelectedIds(newSelectedIds);
  };

  const handleExpandRow = (event, id) => {
    const expandedIndex = expanded.indexOf(id);
    let newExpanded = [];

    if (expandedIndex === -1) {
      newExpanded = newExpanded.concat(expanded, id);
    } else if (expandedIndex === 0) {
      newExpanded = newExpanded.concat(expanded.slice(1));
    } else if (expandedIndex === expanded.length - 1) {
      newExpanded = newExpanded.concat(expanded.slice(0, -1));
    } else if (expandedIndex > 0) {
      newExpanded = newExpanded.concat(
        expanded.slice(0, expandedIndex),
        expanded.slice(expandedIndex + 1)
      );
    }

    setExpanded(newExpanded);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  return {
    rows,
    searchValue,
    order,
    orderBy,
    selected,
    selectedIds,
    expanded,
    page,
    rowsPerPage,
    setExpanded,
    handleChangeSort,
    handleSelectAllClick,
    handleClick,
    handleExpandRow,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSearchValue,
  };
};
