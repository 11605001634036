import {
  Home,
  Clients,
  Commissions,
  ConfirmSignup,
  NewOrder,
  SentOrders,
  Ingredients,
  Login,
  NewReceipt,
  SavedReceipts,
  ResetPassword,
  SentReceipts,
  CreatedReceipts,
  Signup,
  ResetPasswordConfirm,
  Products,
  FavoriteReceipts,
  Statistics,
  Shipment,
  ShipmentItem,
} from "../pages";
import {
  Home as HomeIcon,
  ContactMail,
  Bookmarks,
  Send,
  ShoppingCart,
  CreditCard,
  Spa,
  Assignment,
  NoteAdd,
  AddShoppingCart,
  Inventory,
  Favorite,
  BarChart,
  LocalShipping,
} from "@mui/icons-material";
import { groups } from "./enums/auth";

export const publicPages = [
  {
    title: "Logowanie",
    path: "/logowanie",
    component: <Login />,
  },
  {
    title: "Rejestracja",
    path: "/rejestracja",
    component: <Signup />,
  },
  {
    title: "Potwierdzenie rejestracji",
    path: "/potwierdz",
    component: <ConfirmSignup />,
  },
  {
    title: "Resetowanie hasła",
    path: "/reset-hasla",
    component: <ResetPassword />,
  },
  {
    title: "Zmiana hasła",
    path: "/zmiana-hasla",
    component: <ResetPasswordConfirm />,
  },
];

export const privatePages = [
  {
    title: "Strona główna",
    path: "/",
    component: <Home />,
    icon: <HomeIcon />,
  },
  {
    title: "Klienci",
    path: "/klienci",
    component: <Clients />,
    icon: <ContactMail />,
    group: groups.user,
  },
  {
    title: "Przegląd kosztów",
    path: "/prowizje",
    component: <Commissions />,
    icon: <CreditCard />,
    group: groups.user,
  },
  {
    title: "Statystyki",
    path: "/statystyki",
    component: <Statistics />,
    icon: <BarChart />,
    group: groups.admin,
  },
  {
    title: "Wysyłka",
    path: "/wysylka",
    component: <Shipment />,
    icon: <LocalShipping />,
    group: groups.scanner,
  },
  {
    path: "/wysylka/:id",
    component: <ShipmentItem />,
    hidden: true,
    group: groups.scanner,
  },
  { break: true },
  {
    title: "Składniki",
    path: "/skladniki",
    component: <Ingredients />,
    icon: <Spa />,
  },
  {
    title: "Przykładowe recepty",
    path: "/przykladowe-recepty",
    component: <SavedReceipts />,
    icon: <Bookmarks />,
    group: groups.user,
  },
  {
    title: "Polubione recepty",
    path: "/polubione-recepty",
    component: <FavoriteReceipts />,
    icon: <Favorite />,
    group: groups.user,
  },
  {
    title: "Nowa recepta",
    path: "/nowa-recepta",
    component: <NewReceipt />,
    icon: <NoteAdd />,
    group: groups.user,
  },
  {
    title: "Stworzone recepty",
    path: "/stworzone-recepty",
    component: <CreatedReceipts />,
    icon: <Assignment />,
    group: groups.user,
  },
  {
    title: "Wysłane recepty",
    path: "/wyslane-recepty",
    component: <SentReceipts />,
    icon: <Send />,
    group: groups.user,
  },
  { break: true },
  {
    title: "Produkty",
    path: "/produkty",
    component: <Products />,
    icon: <Inventory />,
  },
  {
    title: "Nowe zamówienie",
    path: "/nowe-zamowienie",
    component: <NewOrder />,
    icon: <AddShoppingCart />,
    group: groups.user,
  },
  {
    title: "Zamówienia",
    path: "/zamowienia",
    component: <SentOrders />,
    icon: <ShoppingCart />,
    group: groups.user,
  },
];
