import React, { useState } from "react";
import _ from "lodash";
import {
  Box,
  TableCell,
  TableRow,
  Checkbox,
  IconButton,
  Collapse,
  Tooltip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
} from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Delete,
  Edit,
  Check,
  ContentCopy,
  Favorite,
  FavoriteBorder,
  MoreVert,
  InventoryOutlined,
} from "@mui/icons-material";
import { OrderContent, ReceiptContent, ClientContent } from "../..";

const GridRow = ({
  type,
  row,
  columns,
  index,
  isItemSelected,
  isItemExpanded,
  labelId,
  checking,
  expanding,
  expandContent,
  handleExpandRow,
  onSetActiveItem,
  handleClick,
  isAccept,
  isEdit,
  isDelete,
  isCopy,
  isFavorite,
  isShipping,
  deleteTitle,
  deleteIcon,
  disabled,
  onRowClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const manageOptions = [
    {
      visible: isAccept,
      title: "Wyślij",
      onClick: () => onSetActiveItem(row, "accept"),
      icon: <Check fontSize="small" color="success" />,
    },
    {
      visible: isFavorite,
      title: row.isFavourite ? "Usuń z ulubionych" : "Dodaj do ulubionych",
      onClick: () => onSetActiveItem(row, "like"),
      icon: row.isFavourite ? (
        <Favorite fontSize="small" color="primary" />
      ) : (
        <FavoriteBorder fontSize="small" color="primary" />
      ),
    },
    {
      visible: isEdit,
      title: "Edytuj",
      onClick: () => onSetActiveItem(row, "edit"),
      icon: <Edit fontSize="small" />,
    },
    {
      visible: isCopy,
      title: "Kopiuj",
      onClick: () => onSetActiveItem(row, "copy"),
      icon: <ContentCopy fontSize="small" />,
    },
    {
      visible: isDelete,
      title: !!deleteTitle ? deleteTitle : "Usuń",
      onClick: () => onSetActiveItem(row, "delete"),
      icon: !!deleteIcon ? (
        deleteIcon
      ) : (
        <Delete fontSize="small" color="error" />
      ),
    },
    {
      visible: isShipping,
      title: "Spakuj",
      onClick: () => onSetActiveItem(row, "shipping"),
      icon: <InventoryOutlined fontSize="small" />,
    },
  ];

  const activeManageOptions = _.filter(manageOptions, (item) => item.visible);
  const manageOptionsLength = activeManageOptions.length;

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheck = async (e) => {
    handleClick(e, row.id);
  };

  const handleExpand = (e) => {
    handleExpandRow(e, row.id);
  };

  const ManageButtons = () =>
    _.map(activeManageOptions, (item) => (
      <Tooltip key={item.title} title={item.title}>
        <IconButton onClick={item.onClick} disabled={disabled}>
          {item.icon}
        </IconButton>
      </Tooltip>
    ));

  const ManageList = () =>
    _.map(activeManageOptions, (item) => (
      <MenuItem
        key={item.title}
        onClick={() => {
          item.onClick();
          handleClose();
        }}
        disabled={disabled}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText>{item.title}</ListItemText>
      </MenuItem>
    ));

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected || isItemExpanded}
        onClick={() => {
          if (!!onRowClick) onRowClick(row.id);
        }}
      >
        {checking && (
          <TableCell padding="checkbox" size="small">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": labelId,
              }}
              onChange={handleCheck}
            />
          </TableCell>
        )}

        {expanding && (
          <TableCell size="small">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleExpand}
            >
              {isItemExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}

        {_.map(columns, (column) => (
          <TableCell
            key={`${row.id}-${index}-${column.id}`}
            component="td"
            align={column.numeric ? "right" : "left"}
            scope="row"
            size="small"
          >
            <Box
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineClamp: 3,
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
            >
              {column.valueGetter
                ? column.valueGetter(row[column.id])
                : row[column.id]}
            </Box>
          </TableCell>
        ))}

        {!!manageOptionsLength ? (
          <TableCell component="td" align={"center"} scope="row" size="small">
            {manageOptionsLength > 3 ? (
              <>
                <Tooltip title="Więcej">
                  <IconButton
                    id="more-button"
                    aria-controls={open ? "more-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleOpen}
                    disabled={disabled}
                  >
                    <MoreVert fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="more-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "more-button",
                  }}
                >
                  <ManageList />
                </Menu>
              </>
            ) : (
              <ManageButtons />
            )}
          </TableCell>
        ) : null}
      </TableRow>

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: "unset" }}
          colSpan={12}
          size="small"
        >
          <Collapse in={isItemExpanded}>
            <Box sx={{ margin: "1rem 2rem" }}>
              {expandContent === "receipt" ? (
                <ReceiptContent
                  data={row}
                  type={type}
                  shouldDownload={isItemExpanded}
                />
              ) : expandContent === "order" ? (
                <OrderContent data={row} shouldDownload={isItemExpanded} />
              ) : expandContent === "client" ? (
                <ClientContent
                  data={row}
                  type={type}
                  shouldDownload={isItemExpanded}
                />
              ) : (
                expandContent
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default GridRow;
