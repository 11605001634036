import {
  Grid,
  Autocomplete as MuiAutocomplete,
  TextField,
} from "@mui/material";
import React from "react";

const Autocomplete = ({
  value,
  label,
  optionLabel,
  gridProps,
  error,
  errorMessage,
  prefixIcon,
  sx,
  ...rest
}) => {
  const getOptionLabel = (option) => {
    if (optionLabel) return optionLabel(option);
    else return option.label;
  };

  const isEqual = (option, value) => option.id === value.id;

  const renderInput = (params) => (
    <TextField
      {...params}
      label={label}
      error={error}
      helperText={error && errorMessage}
    />
  );

  return (
    <Grid
      item
      {...gridProps}
      sx={{
        ...(!!prefixIcon && {
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          width: "100%",
        }),
      }}
    >
      {!!prefixIcon && prefixIcon}
      <MuiAutocomplete
        value={value || null}
        disablePortal
        size="small"
        renderInput={renderInput}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isEqual}
        disableClearable
        sx={{ flex: 1, ...sx }}
        {...rest}
      />
    </Grid>
  );
};

export default Autocomplete;
