import { Typography } from "@mui/material";
import React from "react";

const PageTitle = ({ children }) => (
  <Typography
    sx={{
      flex: "1 1",
      p: "16px",
    }}
    variant="h6"
    id="tableTitle"
    component="div"
  >
    {children}
  </Typography>
);

export default PageTitle;
