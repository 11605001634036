import React from "react";
import _ from "lodash";
import {
  TableBody as MuiTableBody,
  TableCell,
  TableRow as MuiTableRow,
} from "@mui/material";
import { getComparator, stableSort } from "../table.utils";
import TableRow from "./TableRow";

const TableBody = ({
  type,
  columns,
  checking,
  expanding,
  rows,
  order,
  orderBy,
  selected,
  expanded,
  page,
  rowsPerPage,
  handleClick,
  handleExpandRow,
  expandContent,
  expandColumns,
  isAccept,
  isEdit,
  isDelete,
  isCopy,
  deleteTitle,
  deleteIcon,
  onSetActiveItem,
}) => {
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const isExpanded = (id) => expanded.indexOf(id) !== -1;

  return (
    <MuiTableBody>
      {rows.length ? (
        stableSort(rows, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const isItemExpanded = isExpanded(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                key={`row-${row.id}-${index}`}
                type={type}
                row={row}
                columns={columns}
                index={index}
                isItemSelected={isItemSelected}
                isItemExpanded={isItemExpanded}
                labelId={labelId}
                checking={checking}
                expanding={expanding}
                expandContent={expandContent}
                expandColumns={expandColumns}
                handleExpandRow={handleExpandRow}
                onSetActiveItem={onSetActiveItem}
                handleClick={handleClick}
                isAccept={isAccept}
                isEdit={isEdit}
                isDelete={isDelete}
                isCopy={isCopy}
                deleteTitle={deleteTitle}
                deleteIcon={deleteIcon}
              />
            );
          })
      ) : (
        <MuiTableRow>
          <TableCell colSpan={columns.length + 2} align="center">
            Brak danych do wyświetlenia...
          </TableCell>
        </MuiTableRow>
      )}
    </MuiTableBody>
  );
};

export default TableBody;
