import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  useTheme,
} from "@mui/material";
import {
  Autocomplete,
  FormContainer,
  Input,
  PageTitle,
  Popover,
} from "../../components";
import _ from "lodash";
import {
  getClientLabel,
  getProductLabel,
  getReceiptLabel,
} from "../../utils/helpers/app";
import { hasProductError } from "./utlis";
import { commissionTypes, dialogTypes } from "../../utils/enums";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Clear } from "@mui/icons-material";

export const OrderForm = ({
  products,
  receipts,
  isCalculated,
  isWaiting,
  formData,
  errorData,
  clients,
  handleChangeForm,
  handleAddProduct,
  handleDeleteProduct,
  setDialog,
}) => {
  const [activeIndex, setActiveIndex] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenProduct = (event, index) => {
    setAnchorEl(event.currentTarget);
    setActiveIndex(index);
  };

  const handleCloseProduct = () => {
    setAnchorEl(null);
    setActiveIndex();
  };

  return (
    <Paper>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        paddingRight="16px"
        gap="1rem"
      >
        <PageTitle>Nowe zamówienie</PageTitle>
        <Button
          startIcon={<Clear />}
          size="small"
          onClick={() =>
            setDialog(
              dialogTypes.accept,
              "Czy chcesz wyczyścić aktualny formularz?"
            )
          }
        >
          Wyczyść formularz
        </Button>
      </Box>

      <FormContainer title="Dane zamówienia">
        <Autocomplete
          id="autocomplete-receipt"
          label="Dołączyć do recepty"
          value={formData["receipt"]}
          options={receipts}
          onChange={handleChangeForm}
          disabled={isWaiting || isCalculated}
          optionLabel={getReceiptLabel}
          error={errorData["receipt"].value}
          errorMessage="Wybierz opcję."
          gridProps={{ xs: 12, sm: 3 }}
        />
        <Autocomplete
          id="autocomplete-client"
          label="Klient"
          value={formData["client"]}
          options={clients}
          onChange={handleChangeForm}
          disabled={!!formData["receipt"].id || isWaiting || isCalculated}
          optionLabel={getClientLabel}
          error={errorData["client"].value}
          errorMessage="Wybierz klienta."
          gridProps={{ xs: 12, sm: 4 }}
        />
        <Input
          id="address"
          label="Adres dostawy"
          value={formData["address"]}
          onChange={handleChangeForm}
          disabled={!!formData["receipt"].id || isWaiting || isCalculated}
          error={errorData["address"].value}
          errorMessage="Uzupełnij adres."
          helperText={
            !errorData["address"].value &&
            "Wpisz adres w kolejności ulica | kod pocztowy | miasto - rozdzielając je przecinkami."
          }
          gridProps={{ xs: 12, sm: 5 }}
        />
      </FormContainer>

      <FormContainer title="Produkty" alignItems="center">
        {_.map(formData.products, (product, index) => (
          <React.Fragment key={product.id}>
            <Popover
              open={index === activeIndex}
              anchorEl={anchorEl}
              onClose={handleCloseProduct}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: product.value?.description,
                }}
              />
            </Popover>
            <Autocomplete
              id={`autocomplete-products-${product.id}`}
              label="Nazwa produktu"
              value={formData["products"][product.id].value}
              options={products}
              onChange={handleChangeForm}
              optionLabel={getProductLabel}
              disabled={isWaiting || isCalculated}
              error={hasProductError("value", product.id, errorData)}
              errorMessage="Wybierz produkt."
              prefixIcon={
                <IconButton
                  onClick={(e) => handleOpenProduct(e, index)}
                  size="small"
                  disabled={!product.value?.description}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              }
              gridProps={{ xs: 8, md: 4 }}
            />
            <Input
              id={`autocomplete-products-${product.id}-count`}
              label="Ilość"
              type="number"
              inputProps={{ min: "0" }}
              value={formData["products"][product.id].count}
              onChange={handleChangeForm}
              disabled={isWaiting || isCalculated}
              error={hasProductError("count", product.id, errorData)}
              errorMessage="Wpisz ilość."
              gridProps={{ xs: 4, md: 2 }}
            />
            <Input
              id={`autocomplete-products-${product.id}-price`}
              label="Kwota"
              value={formData["products"][product.id].price}
              disabled={isWaiting || isCalculated}
              InputProps={{
                readOnly: true,
              }}
              gridProps={{ xs: 3, md: 2 }}
            />
            <Input
              id={`autocomplete-products-${product.id}-priceVat`}
              label="Kwota z VAT"
              value={formData["products"][product.id].priceVat}
              disabled={isWaiting || isCalculated}
              InputProps={{
                readOnly: true,
              }}
              gridProps={{ xs: 3, md: 2 }}
            />
            <Grid item xs={3} md={1}>
              <Button
                variant="outlined"
                fullWidth
                disabled={
                  isWaiting || isCalculated || formData?.products?.length === 1
                }
                onClick={() =>
                  handleDeleteProduct(formData["products"][product.id].id)
                }
              >
                Usuń
              </Button>
            </Grid>
            {formData.products.length === index + 1 && (
              <Grid item xs={3} md={1}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={isWaiting || isCalculated}
                  onClick={handleAddProduct}
                >
                  Nowy
                </Button>
              </Grid>
            )}
            {index + 1 !== formData?.products?.length && (
              <Divider flexItem sx={{ width: "100%", paddingTop: "16px" }} />
            )}
          </React.Fragment>
        ))}
      </FormContainer>

      <FormContainer title="Notatki">
        <Input
          id="notesForClient"
          label="Notatka dla klienta"
          value={formData["notesForClient"]}
          onChange={handleChangeForm}
          disabled={isWaiting || isCalculated}
          multiline
          rows={3}
          error={errorData["notesForClient"].value}
          errorMessage="Wpisz prawidłowo notatkę dla klienta."
          gridProps={{ xs: 12, md: 6 }}
        />
        <Input
          id="notesForEmployee"
          label="Notatka dla pracowników"
          value={formData["notesForEmployee"]}
          onChange={handleChangeForm}
          disabled={isWaiting || isCalculated}
          multiline
          rows={3}
          helperText="Informacje dotyczące adresów będą ignorowane w powyższej nocie."
          error={errorData["notesForEmployee"].value}
          errorMessage="Wpisz prawidłowo komentarz dla pracowników."
          gridProps={{ xs: 12, md: 6 }}
        />
      </FormContainer>
    </Paper>
  );
};

export const OrderPricesForm = ({
  isCalculated,
  isWaiting,
  priceData,
  errorPriceData,
  handleChangePriceForm,
}) =>
  isCalculated && (
    <Paper>
      <PageTitle>Koszty</PageTitle>

      <FormContainer title="Ceny">
        <Autocomplete
          id={`autocomplete-commissionType`}
          label="Typ prowizji"
          value={priceData["commissionType"]}
          options={commissionTypes}
          onChange={handleChangePriceForm}
          disabled={isWaiting || !isCalculated}
          error={errorPriceData["commissionType"].value}
          errorMessage="Wybierz typ prowizji."
          gridProps={{ xs: 6, sm: 4 }}
        />
        <Input
          id="commission"
          label="Wartość prowizji"
          type="number"
          inputProps={{ min: "0" }}
          value={priceData["commission"]}
          onChange={handleChangePriceForm}
          disabled={isWaiting || !isCalculated}
          error={errorPriceData["commission"].value}
          errorMessage="Wpisz prowizję."
          gridProps={{ xs: 6, sm: 4 }}
        />
        <Input
          id="commissionPrice"
          label="Kwota prowizji bez VAT"
          type="number"
          inputProps={{ min: "0" }}
          value={priceData["commissionPrice"]}
          disabled
          gridProps={{ xs: 6, sm: 4 }}
        />
        {/* <Input
          id="deliveryCost"
          label="Koszt dostawy z VAT"
          type="number"
          inputProps={{ min: "0" }}
          value={priceData["deliveryCost"]}
          onChange={handleChangePriceForm}
          disabled
          gridProps={{ xs: 6, sm: 3 }}
        /> */}
        <Input
          id="ppp"
          label="Kwota produktów bez prowizji/VAT"
          type="number"
          inputProps={{ min: "0" }}
          disabled
          value={priceData["ppp"]}
          gridProps={{ xs: 6, sm: 4 }}
        />
        <Input
          id="spp"
          label="Kwota sprzedaży z prowizją/VAT"
          type="number"
          inputProps={{ min: "0" }}
          disabled
          value={priceData["spp"]}
          gridProps={{ xs: 6, sm: 4 }}
        />
        <Input
          id="sppp"
          label="Całkowity koszt"
          type="number"
          inputProps={{ min: "0" }}
          disabled
          value={priceData["sppp"]}
          gridProps={{ xs: 12, sm: 4 }}
        />
      </FormContainer>
    </Paper>
  );
