import _ from "lodash";

export const commissionSumColumns = [
  {
    obj: "commision",
    title: "Prowizja",
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł bez VAT`,
  },
  {
    obj: "pppG",
    title: "Koszt wyprodukowania granulatów",
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł bez VAT`,
  },
  {
    obj: "pppH",
    title: "Koszt wyprodukowania ziół",
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł bez VAT`,
  },
  {
    obj: "sppp",
    title: "Kwota całkowita z VAT",
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł z VAT`,
  },
  {
    obj: "count",
    title: "Ilość",
  },
];

export const commissionColumns = [
  {
    id: "barcodeText",
    label: "ID",
    numeric: false,
  },
  {
    id: "pppType",
    label: "Rodzaj",
    numeric: false,
    valueGetter: (value) => (value === "H" ? "Zioła" : "Granulaty"),
  },
  {
    id: "commision",
    label: "Prowizja",
    numeric: true,
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł`,
  },
  {
    id: "ppp",
    label: "Koszt wyprodukowania",
    numeric: true,
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł`,
  },
  {
    id: "sppp",
    label: "Kwota całkowita z VAT",
    numeric: true,
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł`,
  },
];

export const commissionAllDoctorsColumns = [
  {
    id: "fullName",
    label: "Imię Nazwisko",
    numeric: false,
  },
  {
    id: "createdBy",
    label: "E-mail",
    numeric: false,
  },
  {
    id: "count",
    label: "Ilość",
    numeric: true,
  },
  {
    id: "pppType",
    label: "Rodzaj",
    numeric: false,
    valueGetter: (value) => (value === "H" ? "Zioła" : "Granulaty"),
  },
  {
    id: "commision",
    label: "Prowizja",
    numeric: true,
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł`,
  },
  {
    id: "ppp",
    label: "Koszt wyprodukowania",
    numeric: true,
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł`,
  },
  {
    id: "sppp",
    label: "Kwota całkowita z VAT",
    numeric: true,
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł`,
  },
];
