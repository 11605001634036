import React from "react";
import _ from "lodash";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { GridRow } from ".";
import Loading from "../../Loading";

const GridBody = ({
  type,
  columns,
  checking,
  expanding,
  rows,
  selected,
  expanded,
  handleClick,
  handleExpandRow,
  expandContent,
  loading,
  isAccept,
  isEdit,
  isDelete,
  isCopy,
  isFavorite,
  isShipping,
  deleteTitle,
  deleteIcon,
  onSetActiveItem,
  disabled,
  onRowClick,
}) => {
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const isExpanded = (id) => expanded.indexOf(id) !== -1;

  return (
    <TableBody sx={{ position: "relative" }}>
      <tr>
        <td>
          <Loading
            visible={loading}
            sx={{ position: "absolute", overflow: "hidden", zIndex: "inherit" }}
          />
        </td>
      </tr>
      {rows.length ? (
        _.map(rows, (row, index) => {
          const isItemSelected = isSelected(row.id);
          const isItemExpanded = isExpanded(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <GridRow
              key={`row-${row.id}-${index}`}
              type={type}
              row={row}
              columns={columns}
              index={index}
              isItemSelected={isItemSelected}
              isItemExpanded={isItemExpanded}
              labelId={labelId}
              checking={checking}
              expanding={expanding}
              expandContent={expandContent}
              handleExpandRow={handleExpandRow}
              onSetActiveItem={onSetActiveItem}
              handleClick={handleClick}
              isAccept={isAccept}
              isEdit={isEdit}
              isDelete={isDelete}
              isCopy={isCopy}
              isFavorite={isFavorite}
              isShipping={isShipping}
              deleteTitle={deleteTitle}
              deleteIcon={deleteIcon}
              disabled={disabled}
              onRowClick={onRowClick}
            />
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={columns.length + 2} align="center">
            Brak danych do wyświetlenia...
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default GridBody;
