import { LoadingButton } from "@mui/lab";
import { Button, Box, Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Autocomplete,
  Dialog,
  FormContainer,
  Input,
} from "../../../components";
import { setUser } from "../../../stores/authStore";
import { commissionTypes } from "../../../utils/enums";
import {
  changePassword,
  inviteUser,
  updateUserData,
} from "../../../utils/helpers/auth";
import { emailRegex, validate, validateInput } from "../../../utils/validators";
import {
  initialErrorProfileData,
  initialErrorSettingsData,
  initialErrorPasswordData,
  initialProfileData,
  initialPasswordData,
} from "./utils";

const ProfileDialog = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const settings = useSelector((state) => state.auth.settings);

  const [profileData, setProfileData] = useState(initialProfileData(user));
  const [errorProfileData, setErrorProfileData] = useState(
    initialErrorProfileData
  );
  const [isEditable, setIsEditable] = useState(false);
  const [isSettingsEditable, setIsSettingsEditable] = useState(false);
  const [settingsData, setSettingsData] = useState({ ...settings });
  const [errorSettingsData, setErrorSettingsData] = useState(
    initialErrorSettingsData
  );

  const [isPasswordEditable, setIsPasswordEditable] = useState(false);
  const [passwordData, setPasswordData] = useState(initialPasswordData);
  const [errorPasswordData, setErrorPasswordData] = useState(
    initialErrorPasswordData
  );
  const [inviteValue, setInviteValue] = useState("");
  const [isInviteError, setIsInviteError] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    return () => {
      setProfileData(initialProfileData(user));
      setErrorProfileData(initialErrorProfileData);
      setSettingsData({ ...settings });
      setErrorSettingsData(initialErrorSettingsData);
      setIsEditable(false);
      setInviteValue("");
      setIsInviteError(false);
      setIsWaiting(false);
    };
  }, []);

  useEffect(() => {
    setProfileData({
      lastName: user?.lastName || "",
      firstName: user?.firstName || "",
      email: user?.email || "",
      phoneNumber: user?.phoneNumber || "",
    });
  }, [user]);

  useEffect(() => {
    setSettingsData({ ...settings });
  }, [settings]);

  const onChangeForm = (e) => {
    setProfileData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onChangeSettings = (e, obj) => {
    const isAutocomplete = e.target.id.split("-")[0] === "autocomplete";
    if (isAutocomplete) {
      const objName = e.target.id.split("-")[1];
      setSettingsData((prevState) => ({
        ...prevState,
        [objName]: obj,
      }));
    } else
      setSettingsData((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }));
  };

  const onChangePasswordForm = (e) => {
    setPasswordData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleEditableProfile = (type) => {
    if (type === "profile") {
      setIsEditable((prevState) => !prevState);
      setProfileData({
        lastName: user?.lastName || "",
        firstName: user?.firstName || "",
        email: user?.email || "",
        phoneNumber: user?.phoneNumber || "",
      });
      setErrorProfileData({ ...initialErrorProfileData });
    } else if (type === "settings") {
      setIsSettingsEditable((prevState) => !prevState);
      setSettingsData({ ...settings });
      setErrorSettingsData({ ...initialErrorSettingsData });
    } else if (type === "password") {
      setIsPasswordEditable((prevState) => !prevState);
      setPasswordData({ ...initialPasswordData });
      setErrorPasswordData({ ...initialErrorPasswordData });
    }
  };

  const onUpdateProfile = async (type) => {
    setErrorProfileData(initialErrorProfileData);
    setIsWaiting(true);

    const { success, errors } = validate(profileData, errorProfileData);

    if (success) {
      const data = {
        id: user.id,
        ...profileData,
        commisionRaw: settingsData.commission || "0",
        commisionType: settingsData.commissionType.value,
      };
      const responseData = await updateUserData(token, data);
      if (responseData.success) {
        dispatch(setUser(responseData.data));
        setIsEditable(false);
        setIsSettingsEditable(false);
      } else {
        if (type === "profile")
          setErrorProfileData((prevState) => ({
            ...prevState,
            form: { value: true, message: responseData.message },
          }));
        else if (type === "settings")
          setErrorSettingsData((prevState) => ({
            ...prevState,
            form: { value: true, message: responseData.message },
          }));
      }
    } else {
      if (type === "profile") setErrorProfileData({ ...errors });
      else if (type === "settings") setErrorSettingsData({ ...errors });
    }
    setIsWaiting(false);
  };

  const onChangePassword = async () => {
    setErrorPasswordData(initialErrorPasswordData);
    setIsWaiting(true);

    const { success, errors } = validate(passwordData, errorPasswordData);

    if (success) {
      const responseData = await changePassword(token, passwordData);

      if (responseData.success) {
        setIsPasswordEditable(false);
        setPasswordData({ ...initialPasswordData });
      } else {
        setErrorPasswordData((prevState) => ({
          ...prevState,
          form: { value: true, message: responseData.message },
        }));
      }
    } else {
      setErrorPasswordData({ ...errors });
    }
    setIsWaiting(false);
  };

  const changeInviteValue = (e) => {
    setInviteValue(e.target.value);
  };

  const onSentInvite = async () => {
    setIsInviteError(false);
    setIsWaiting(true);
    if (validateInput(inviteValue, emailRegex)) {
      const { success } = await inviteUser(token, inviteValue);
      if (success) setInviteValue("");
    } else setIsInviteError(true);
    setIsWaiting(false);
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={"Dane użytkownika"}
      content={
        <>
          <FormContainer title="Dane użytkownika">
            <Input
              id="lastName"
              label="Nazwisko"
              value={profileData["lastName"]}
              onChange={onChangeForm}
              disabled={!isEditable || isWaiting}
              error={errorProfileData["lastName"]?.value}
              errorMessage={"Wpisz nazwisko."}
              gridProps={{ xs: 12, sm: 6 }}
            />
            <Input
              id="firstName"
              label="Imię"
              value={profileData["firstName"]}
              onChange={onChangeForm}
              disabled={!isEditable || isWaiting}
              error={errorProfileData["firstName"].value}
              errorMessage={"Wpisz imię."}
              gridProps={{ xs: 12, sm: 6 }}
            />
            <Input
              id="phoneNumber"
              label="Telefon"
              value={profileData["phoneNumber"]}
              onChange={onChangeForm}
              disabled={!isEditable || isWaiting}
              error={errorProfileData["phoneNumber"].value}
              errorMessage={"Wpisz numer telefonu."}
              gridProps={{ xs: 12 }}
            />
            <Input
              id="email"
              label="Adres e-mail"
              value={profileData["email"]}
              disabled={true}
              helperText="Brak możliwości zmiany."
              gridProps={{ xs: 12 }}
            />

            {errorProfileData["form"].value && (
              <Alert severity="error">
                {errorProfileData.form.message || "Podane dane są błędne!"}
              </Alert>
            )}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <Button onClick={() => handleEditableProfile("profile")}>
                {isEditable ? "Anuluj" : "Edytuj profil"}
              </Button>
              {isEditable && (
                <LoadingButton
                  onClick={() => onUpdateProfile("profile")}
                  variant="contained"
                  loading={isWaiting}
                >
                  Zapisz
                </LoadingButton>
              )}
            </Box>
          </FormContainer>

          <FormContainer title={"Ustawienia domyślne"}>
            <Autocomplete
              id={`autocomplete-commissionType`}
              label="Typ prowizji"
              value={settingsData["commissionType"]}
              options={commissionTypes}
              onChange={onChangeSettings}
              disabled={!isSettingsEditable || isWaiting}
              error={errorSettingsData["commissionType"].value}
              errorMessage="Wybierz typ prowizji."
              gridProps={{ xs: 12, sm: 6 }}
            />
            <Input
              id="commission"
              label="Wartość prowizji"
              type="number"
              inputProps={{ min: "0" }}
              value={settingsData["commission"] || "0"}
              onChange={onChangeSettings}
              disabled={!isSettingsEditable || isWaiting}
              error={errorSettingsData["commission"].value}
              errorMessage="Wpisz prowizję."
              gridProps={{ xs: 12, sm: 6 }}
            />

            {errorSettingsData["form"].value && (
              <Alert severity="error">
                {errorSettingsData.form.message || "Podane dane są błędne!"}
              </Alert>
            )}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "0.25rem",
              }}
            >
              <Button onClick={() => handleEditableProfile("settings")}>
                {isSettingsEditable ? "Anuluj" : "Edytuj"}
              </Button>
              {isSettingsEditable && (
                <LoadingButton
                  onClick={() => onUpdateProfile("settings")}
                  variant="contained"
                  loading={isWaiting}
                >
                  Zapisz
                </LoadingButton>
              )}
            </Box>
          </FormContainer>

          <FormContainer title={"Zmiana hasła"}>
            <Input
              id="oldPassword"
              label="Aktualne hasło"
              type="password"
              value={passwordData["oldPassword"]}
              onChange={onChangePasswordForm}
              disabled={!isPasswordEditable || isWaiting}
              error={errorPasswordData["oldPassword"].value}
              errorMessage="Wpisz aktualne hasło."
              gridProps={{ xs: 12 }}
            />
            <Input
              id="newPassword1"
              label="Nowe hasło"
              type="password"
              value={passwordData["newPassword1"]}
              onChange={onChangePasswordForm}
              disabled={!isPasswordEditable || isWaiting}
              error={errorPasswordData["newPassword1"].value}
              errorMessage="Niepoprawne hasło (powinno zawierać przynajmniej 8 znaków, wielką i małą literę, minimum jedną cyfrę i jeden znak specjalny)."
              gridProps={{ xs: 12 }}
            />
            <Input
              id="newPassword2"
              label="Potwierdź hasło"
              type="password"
              value={passwordData["newPassword2"]}
              onChange={onChangePasswordForm}
              disabled={!isPasswordEditable || isWaiting}
              error={errorPasswordData["newPassword2"].value}
              errorMessage="Potwierdź nowe hasło."
              gridProps={{ xs: 12 }}
            />

            {errorPasswordData["form"].value && (
              <Alert severity="error">
                {errorPasswordData.form.message || "Podane dane są błędne!"}
              </Alert>
            )}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "0.25rem",
              }}
            >
              <Button onClick={() => handleEditableProfile("password")}>
                {isPasswordEditable ? "Anuluj" : "Edytuj"}
              </Button>
              {isPasswordEditable && (
                <LoadingButton
                  onClick={onChangePassword}
                  variant="contained"
                  loading={isWaiting}
                >
                  Zapisz
                </LoadingButton>
              )}
            </Box>
          </FormContainer>

          {user?.isSuperuser || user?.isStaff ? (
            <>
              <FormContainer title={"Zaproś użytkownika"}>
                <Input
                  id="invite"
                  label="Adres e-mail"
                  value={inviteValue}
                  onChange={changeInviteValue}
                  disabled={isWaiting}
                  error={isInviteError}
                  helperText="Wpisz adres e-mail, na który zostanie wysłane zaproszenie do platformy."
                  gridProps={{ xs: 12 }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") onSentInvite();
                  }}
                  variant="outlined"
                />
              </FormContainer>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <LoadingButton
                  onClick={onSentInvite}
                  variant="contained"
                  loading={isWaiting}
                >
                  Zaproś
                </LoadingButton>
              </Box>
            </>
          ) : null}
        </>
      }
    />
  );
};

export default ProfileDialog;
