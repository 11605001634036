import { Alert } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dialog, FormContainer, Input } from "../../../components";
import { onAddClient, onEditClient } from "../../../stores/appStore";
import { Endpoints } from "../../../utils/endpoints";
import { fetchTypes } from "../../../utils/enums";
import { getClientAddress } from "../../../utils/helpers/app";
import {
  useFetchData,
  useFormState,
  useLoadingState,
} from "../../../utils/hooks";
import { initialFormData, initialErrorData } from "./utlis";

const ClientDialog = ({ open, handleClose, activeItem }) => {
  const dispatch = useDispatch();

  const { isWaitingForResponse, changeWaitingForResponse } = useLoadingState();
  const { fetchData } = useFetchData({ changeWaitingForResponse });
  const { formData, errorData, changeFormData, onSubmitForm, resetStates } =
    useFormState({
      initialFormData,
      initialErrorData,
      activeItem,
      fetchData,
    });

  useEffect(() => {
    if (!open || !activeItem) resetStates();
  }, [open, activeItem]);

  const onSubmit = async () => {
    const body = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      fullName: formData.fullName,
      email: formData.email,
      mobile: formData.mobile,
      phone: formData.phone,
      description: formData.description,
      address: {
        street: formData.street.replaceAll(",", ""),
        city: formData.city.replaceAll(",", ""),
        zipCode: formData.zipCode.replaceAll(",", ""),
      },
    };

    function onSuccess(response) {
      const data = {
        ...response.data,
        addressName: getClientAddress(response.data.address),
      };
      if (!!activeItem) dispatch(onEditClient(data));
      else dispatch(onAddClient(data));
      handleClose();
    }

    if (!!activeItem)
      onSubmitForm(
        fetchTypes.patch,
        `${Endpoints.clients}${activeItem.id}/`,
        onSuccess,
        body
      );
    else onSubmitForm(fetchTypes.post, Endpoints.clients, onSuccess, body);
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={!!activeItem ? "Edytowanie klienta" : "Dodawanie klienta"}
      acceptTitle={!!activeItem ? "Edytuj" : "Dodaj"}
      acceptOnClick={onSubmit}
      cancelOnClick={handleClose}
      maxWidth="sm"
      content={
        <>
          <FormContainer title="Dane klienta">
            <Input
              id="lastName"
              label="Nazwisko"
              value={formData["lastName"]}
              onChange={changeFormData}
              disabled={isWaitingForResponse}
              error={errorData["lastName"].value}
              errorMessage={"Wpisz nazwisko z wielkiej litery."}
              gridProps={{ xs: 12, sm: 6 }}
            />
            <Input
              id="firstName"
              label="Imię"
              value={formData["firstName"]}
              onChange={changeFormData}
              disabled={isWaitingForResponse}
              error={errorData["firstName"].value}
              errorMessage={"Wpisz imię z wielkiej litery."}
              gridProps={{ xs: 12, sm: 6 }}
            />
            <Input
              id="fullName"
              label="Pełna nazwa"
              value={formData["fullName"]}
              onChange={changeFormData}
              disabled={isWaitingForResponse}
              error={errorData["fullName"].value}
              errorMessage={"Uzupełnij pełną nazwę."}
              gridProps={{ xs: 12 }}
            />
            <Input
              id="description"
              label="Opis"
              value={formData["description"]}
              onChange={changeFormData}
              disabled={isWaitingForResponse}
              error={errorData["description"].value}
              errorMessage={"Uzupełnij opis."}
              gridProps={{ xs: 12 }}
            />
          </FormContainer>
          <FormContainer title="Dane adresowe">
            <Input
              id="street"
              label="Ulica"
              value={formData["street"]}
              onChange={changeFormData}
              disabled={isWaitingForResponse}
              error={errorData["street"].value}
              errorMessage={"Wpisz ulicę."}
              gridProps={{ xs: 12 }}
            />
            <Input
              id="zipCode"
              label="Kod pocztowy"
              value={formData["zipCode"]}
              onChange={changeFormData}
              disabled={isWaitingForResponse}
              error={errorData["zipCode"].value}
              errorMessage={"Wpisz kod pocztowy (XX-XXX)."}
              gridProps={{ xs: 12, sm: 6 }}
            />
            <Input
              id="city"
              label="Miasto"
              value={formData["city"]}
              onChange={changeFormData}
              disabled={isWaitingForResponse}
              error={errorData["city"].value}
              errorMessage={"Wpisz miasto."}
              gridProps={{ xs: 12, sm: 6 }}
            />
          </FormContainer>
          <FormContainer title="Dane kontaktowe">
            <Input
              id="email"
              label="Adres e-mail"
              value={formData["email"]}
              onChange={changeFormData}
              disabled={isWaitingForResponse}
              error={errorData["email"].value}
              errorMessage={"Wpisz prawidłowy adres e-mail."}
              gridProps={{ xs: 12 }}
            />
            <Input
              id="phone"
              label="Telefon"
              value={formData["phone"]}
              onChange={changeFormData}
              disabled={isWaitingForResponse}
              error={errorData["phone"].value}
              errorMessage={"Wpisz numer telefonu."}
              gridProps={{ xs: 12, sm: 6 }}
            />
            <Input
              id="mobile"
              label="Telefon mobilny"
              value={formData["mobile"]}
              onChange={changeFormData}
              disabled={isWaitingForResponse}
              error={errorData["mobile"].value}
              errorMessage={"Wpisz numer telefonu mobilnego."}
              gridProps={{ xs: 12, sm: 6 }}
            />
          </FormContainer>
          {errorData["form"].value && (
            <Alert severity="error">
              {errorData.form.message || "Podane dane są błędne!"}
            </Alert>
          )}
        </>
      }
    />
  );
};

export default ClientDialog;
