import styled from "@emotion/styled";
import {
  ArrowBack,
  CameraAlt,
  Done,
  FlashOff,
  FlashOn,
  NoPhotography,
} from "@mui/icons-material";
import { AppBar, Box, Button, Fab, IconButton, Toolbar } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const BottomBar = ({
  isCameraOn,
  onScan,
  isFlashOn,
  toggleFlash,
  onAccept,
}) => {
  const navigate = useNavigate();

  const StyledFab = styled(Fab)({
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  });

  const onBack = () => {
    navigate(-1);
  };

  return (
    <AppBar position="fixed" color="inherit" sx={{ top: "auto", bottom: 0 }}>
      <Toolbar>
        <Button onClick={onBack} startIcon={<ArrowBack />}>
          Cofnij
        </Button>
        <StyledFab onClick={onScan} color="primary">
          {isCameraOn ? <NoPhotography /> : <CameraAlt />}
        </StyledFab>
        {isCameraOn && (
          <IconButton color="primary" onClick={toggleFlash}>
            {isFlashOn ? <FlashOff /> : <FlashOn />}
          </IconButton>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Button disabled={isCameraOn} onClick={onAccept} endIcon={<Done />}>
          Potwierdź
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default BottomBar;
