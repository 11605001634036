import _ from "lodash";
import { initialFormData } from "../../pages/newReceipt/utils";

export const getClientAddress = (address) =>
  `${address?.street || "(brak ulicy)"}, ${
    address?.zipCode || "(brak kodu pocztowego)"
  }, ${address?.city || "(brak miasta)"}`;

export const getClientLabel = (client) =>
  `${client?.fullName || "(brak pełnej nazwy)"} -  ${getClientAddress(
    client?.address
  )} / ${client?.phone || "(brak telefonu)"} / ${
    client?.mobile || "(brak telefonu mobilnego)"
  } / ${client?.email || "(brak e-maila)"} - ${client?.id}`;

export const getIngredientLabel = (ingredient) =>
  `${ingredient?.name} - ${ingredient?.nameEn}`;

export const getProductLabel = (product) =>
  `${product?.name}${!!product?.namePl ? " - " + product.namePl : ""}${
    !!product?.nameEn ? " - " + product.nameEn : ""
  }`;

export const getReceiptLabel = (receipt) =>
  `${receipt?.barcodeText ? `${receipt?.barcodeText} - ` : ""}${
    receipt?.name || "(brak nazwy)"
  }${receipt?.clientName ? ` - ${receipt?.clientName}` : ""}`;

export const getErrorResponse = (errors) => {
  return errors?.length
    ? errors[0]?.message?.length
      ? errors[0]?.message[0]
      : `${JSON.stringify(errors[0]?.message)}`
    : !!errors &&
      !!Object.values(errors)?.length &&
      !!Object.values(errors)?.[0]?.[0]
    ? Object.values(errors)[0][0]
    : "Wystąpił problem, skontaktuj się z administratorem.";
};

export const getIngredients = (ingredients, data) => {
  if (!ingredients) {
    return initialFormData.ingredients;
  } else {
    return _.map(
      _.isObject(ingredients) ? _.values(ingredients) : ingredients,
      (ingredient, index) => {
        const currentItem = _.find(
          data,
          (item) => item.code === ingredient.code
        );

        return {
          id: index,
          value: { ...currentItem },
          count: ingredient.count || "",
          dose: ingredient.dose || "",
          total: ingredient.total || "",
        };
      }
    );
  }
};

export const getIngredientsToShow = (ingredients) => {
  return _.map(
    _.isObject(ingredients) ? _.values(ingredients) : ingredients,
    (ingredient) => ({
      ...ingredient,
      category: ingredient.category || "Pozostałe",
      isScanned: false,
    })
  );
};
