import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { receiptCategories } from "../../../utils/enums";
import { ReceiptContent } from "../../../components";
import { isPrivatePageAvailable } from "../../../utils/helpers/auth";

const ShipmentItem = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!isPrivatePageAvailable(user, "/wysylka")) navigate("/");
  }, []);

  return (
    <ReceiptContent
      data={{ id }}
      type={receiptCategories.shipping}
      shouldDownload={true}
    />
  );
};

export default ShipmentItem;
