import { useEffect, useState } from "react";
import { dialogTypes } from "../enums";

export const useDialogState = () => {
  const [dialogType, setVisibleDialogType] = useState(dialogTypes.none);
  const [dialogMessage, setMessage] = useState();

  useEffect(() => {
    return () => {
      setVisibleDialogType(dialogTypes.none);
      setMessage();
    };
  }, []);

  const setDialog = (type, message) => {
    if (!!type) {
      setMessage(message);
      setVisibleDialogType(type);
    } else {
      setMessage();
      setVisibleDialogType(dialogTypes.none);
    }
  };

  const isDialogVisible = (type) => type === dialogType;

  return {
    dialogType,
    dialogMessage,
    isDialogVisible,
    setDialog,
  };
};
