import React, { useEffect, useState } from "react";
import { Container, Box, CssBaseline, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Drawer } from "./components";
import { useLocation } from "react-router-dom";
import { onLogout, setUser } from "../stores/authStore";
import { Dialog, Wrapper } from "../components";
import { useLoadingState, useFetchData, useDialogState } from "../utils/hooks";
import {
  onReset,
  onSetClients,
  onSetIngredientCategories,
  onSetIngredients,
  onSetNews,
  onSetProducts,
} from "../stores/appStore";
import axios from "axios";
import { Endpoints } from "../utils/endpoints";
import { dialogTypes, fetchTypes } from "../utils/enums";
import _ from "lodash";
import { getClientAddress } from "../utils/helpers/app";
import { getAvailablePrivatePages } from "../utils/helpers/auth";

const Layout = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLogin, user } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [hasInitialData, setHasInitialData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { changeDownloading } = useLoadingState();
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();
  const { fetchData } = useFetchData({ changeDownloading });

  const privatePages = getAvailablePrivatePages(user);

  const handleLogout = () => {
    setOpen(false);
    setHasInitialData(false);
    setIsLoading(true);
    dispatch(onLogout());
    dispatch(onReset());
  };

  const getInitialData = () => {
    axios
      .all([
        fetchData(fetchTypes.get, Endpoints.user),
        fetchData(fetchTypes.get, Endpoints.ingredients),
        fetchData(fetchTypes.get, Endpoints.ingredientCategories),
        fetchData(fetchTypes.get, Endpoints.clients),
        fetchData(fetchTypes.get, Endpoints.products),
        fetchData(fetchTypes.get, Endpoints.news),
      ])
      .then(
        axios.spread(
          (
            user,
            ingredients,
            ingredientCategories,
            clients,
            products,
            news
          ) => {
            const responses = [
              user,
              ingredients,
              ingredientCategories,
              clients,
              products,
              news,
            ];

            if (
              user.success &&
              ingredients.success &&
              ingredientCategories.success &&
              clients.success &&
              products.success &&
              news.success
            ) {
              dispatch(setUser(user.data));
              dispatch(
                onSetIngredients(
                  _.map(ingredients.data, (ingredient) => ({
                    ...ingredient,
                    category: ingredient.category || "Pozostałe",
                  }))
                )
              );
              dispatch(onSetIngredientCategories(ingredientCategories.data));
              dispatch(
                onSetClients(
                  _.map(clients.data, (item) => ({
                    ...item,
                    addressName: getClientAddress(item.address),
                  }))
                )
              );
              dispatch(onSetProducts(products.data));
              dispatch(onSetNews(news.data));

              setHasInitialData(true);
              setIsLoading(false);
            } else {
              setDialog(
                dialogTypes.error,
                `Error: "${
                  _.find(responses, (response) => !response.success)?.message
                }". Wystąpił problem podczas pobierania danych. Po zamknięciu okienka zostaniesz wylogowany z profilu. Proszę spróbować zalogować się ponownie. W przypadku ponownego wystąpienia tego powiadomienia proszę o kontakt z administratorem.`
              );
              setIsLoading(false);
            }
          }
        )
      )
      .catch((errors) => {
        setDialog(
          dialogTypes.error,
          "Wystąpił problem podczas pobierania danych. Po zamknięciu okienka zostaniesz wylogowany z profilu. Proszę spróbować zalogować się ponownie. W przypadku ponownego wystąpienia tego powiadomienia proszę o kontakt z administratorem."
        );
        console.log("Errors: ", errors);
      });
  };

  useEffect(() => {
    if (isLogin) {
      if (!hasInitialData) getInitialData();
      if (
        !privatePages.some((page) =>
          window.location.pathname.includes(page.path)
        )
      )
        handleLogout();
    } else handleLogout();
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return isLogin ? (
    <>
      <Dialog
        open={!!user ? !user?.isConfirmed : false}
        title={"Potwierdzenie e-mail"}
        content={
          <>
            <Typography>
              Aby móc korzystać z aplikacji musisz potwierdzić swój adres
              e-mail. Wiadomość została wysłana na wskazany adres. W przypadku
              problemów prosimy o kontakt z administratorem.
            </Typography>
            <Box display="flex" justifyContent="center" marginTop={1}>
              <Button onClick={handleLogout} variant="contained">
                Wyloguj się
              </Button>
            </Box>
          </>
        }
      />
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CssBaseline />
        <AppBar open={open} handleDrawerOpen={handleDrawerOpen} />
        <Box sx={{ padding: "1rem", height: "100%" }}>
          <Wrapper
            isDownloading={isLoading}
            isDialogVisible={isDialogVisible}
            dialogMessage={dialogMessage}
            handleCloseDialog={handleLogout}
          >
            {hasInitialData && children}
          </Wrapper>
        </Box>
        <Drawer open={open} handleDrawerClose={handleDrawerClose} />
      </Box>
    </>
  ) : (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "5rem",
        }}
      >
        {children}
      </div>
    </Container>
  );
};

export default Layout;
