import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { receiptTypes } from "../enums";
import { validate } from "../validators";
import { getClientAddress } from "../helpers/app";
import {
  initialFormData,
  initialErrorData,
  initialPriceData,
  initialErrorPriceData,
  calculateReceipt,
  getReceiptData,
  setReceiptData,
  getIngredientsByType,
  changeIngredientsByType,
} from "../../pages/newReceipt/utils";
import { onSetCopiedItem } from "../../stores/appStore";

export const useReceiptManagement = ({
  setIsCalculated,
  editItem,
  fetchData,
  setDialog,
}) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.auth.settings);
  const ingredients = useSelector((state) => state.app.ingredients);
  const copiedItem = useSelector((state) => state.app.copiedItem);

  const currentIngredients = useRef(initialFormData.ingredients);
  const [formData, setFormData] = useState({
    ...initialFormData,
    receiptType: !!copiedItem
      ? _.find(receiptTypes, (type) => type.value === copiedItem.receiptType)
      : !!editItem
      ? _.find(receiptTypes, (type) => type.value === editItem.receiptType)
      : initialFormData.receiptType,
  });
  const [errorData, setErrorData] = useState(initialErrorData);
  const [priceData, setPriceData] = useState(initialPriceData);
  const [errorPriceData, setErrorPriceData] = useState(initialErrorPriceData);
  const [ingredientsByType, setIngredientsByType] = useState([]);
  const isEditMode = !!editItem;

  const clearStates = () => {
    currentIngredients.current = initialFormData.ingredients;
    setFormData({
      ...initialFormData,
      ingredients: _.map(initialFormData.ingredients, (ingredient) => ({
        ...ingredient,
      })),
    });
    setErrorData({ ...initialErrorData });
    setPriceData({ ...initialPriceData, ...settings });
    setErrorPriceData({ ...initialErrorPriceData });
    setIngredientsByType([]);
    dispatch(onSetCopiedItem(null));
  };

  useEffect(() => {
    return () => {
      clearStates();
    };
  }, []);

  useEffect(() => {
    if (isEditMode || !!copiedItem) {
      const newData = isEditMode ? editItem : copiedItem;

      const { newFormData, newPriceData } = setReceiptData(
        {
          ...newData,
          ...settings,
        },
        ingredients
      );

      currentIngredients.current = newFormData.ingredients;
      setFormData({
        ...newFormData,
        ingredients: _.map(newFormData.ingredients, (ingredient) => ({
          ...ingredient,
        })),
      });
      setPriceData({ ...newPriceData });
    }
  }, [editItem, copiedItem]);

  useEffect(() => {
    setIngredientsByType(
      getIngredientsByType(ingredients, formData?.["receiptType"])
    );

    const newIngredients = changeIngredientsByType(
      currentIngredients.current,
      formData?.["receiptType"],
      ingredients,
      setDialog
    );

    currentIngredients.current = newIngredients;
    setFormData((prevState) => ({
      ...prevState,
      ingredients: _.map(newIngredients, (ingredient) => ({
        ...ingredient,
      })),
    }));
  }, [ingredients, formData?.["receiptType"]]);

  useEffect(() => {
    setPriceData((prevState) => ({ ...prevState, ...settings }));
  }, [settings]);

  const handleChangeForm = async (e, obj) => {
    const isAutocomplete = e.target.id.split("-")[0] === "autocomplete";
    const newFormData = _.cloneDeep(formData);

    if (isAutocomplete) {
      const objName = e.target.id.split("-")[1];
      if (objName === "ingredients") {
        const index = e.target.id.split("-")[2];
        const subObjName = e.target.id.split("-")[3];

        if (subObjName === "count") {
          _.set(
            newFormData,
            ["ingredients", index, subObjName],
            e.target.value
          );
        } else {
          _.set(newFormData, ["ingredients", index, "value"], obj);
        }
      } else {
        _.set(newFormData, objName, obj);
        if (objName === "client") {
          _.set(newFormData, "address", getClientAddress(obj.address));
        }
      }
    } else {
      _.set(newFormData, e.target.id, e.target.value);
    }

    currentIngredients.current = newFormData.ingredients;
    setFormData({ ...newFormData });
  };

  const handleAddIngredient = () => {
    const newValue = {
      id: formData.ingredients.length,
      value: null,
      count: "",
      dose: "",
      total: "",
      totalPrice: "",
    };

    setFormData((prevState) => {
      currentIngredients.current = [...prevState.ingredients, newValue];
      return {
        ...prevState,
        ["ingredients"]: [...prevState.ingredients, newValue],
      };
    });
  };

  const handleDeleteIngredient = (id) => {
    const ing = _.map(
      _.filter(formData["ingredients"], (ingredient) => ingredient.id !== id),
      (item, index) => ({ ...item, id: index })
    );
    const newFormData = {
      ...formData,
      ["ingredients"]: ing,
    };

    currentIngredients.current = ing;
    setFormData({ ...newFormData });
  };

  const handleCheckIngredients = () => {
    const newIngredients = _.filter(
      _.cloneDeep(formData.ingredients),
      (ingredient) => _.isObject(ingredient.value) || !!ingredient.count
    );
    const errorsData = [];
    const ingredientsToCalculate = [];

    if (!newIngredients.length)
      newIngredients.push({
        id: 0,
        value: null,
        count: "",
        dose: "",
        total: "",
      });

    _.forEach(newIngredients, (ingredient) => {
      if (
        !_.isObject(ingredient.value) &&
        !!ingredient.count &&
        ingredient.count !== "0"
      )
        errorsData.push({
          id: ingredient.id,
          value: true,
          count: false,
        });
      else if (
        _.isObject(ingredient.value) &&
        (!ingredient.count || ingredient.count === "0")
      )
        errorsData.push({
          id: ingredient.id,
          value: false,
          count: true,
        });
      else if (
        !_.isObject(ingredient.value) &&
        (!ingredient.count || ingredient.count === "0")
      )
        errorsData.push({
          id: ingredient.id,
          value: true,
          count: true,
        });
      else ingredientsToCalculate.push(ingredient);
    });

    const countSum = _.reduce(
      ingredientsToCalculate,
      (acc, obj) => acc + +obj.count,
      0
    );
    const x = +formData.dailyDose / countSum;

    _.forEach(ingredientsToCalculate, (item, index) => {
      const currentIngredient = _.find(newIngredients, { id: item.id });
      _.set(currentIngredient, "id", index);
      _.set(currentIngredient, "dose", _.round(item.count * x, 2));
      _.set(
        currentIngredient,
        "total",
        _.round(+formData.dosePeriod * item.count * x, 2)
      );
      _.set(currentIngredient, "dosePeriod", +formData.dosePeriod);
      _.set(
        currentIngredient,
        "totalPrice",
        _.round(
          +item.value.price *
            +currentIngredient.dose *
            +currentIngredient.dosePeriod,
          2
        )
      );
    });

    return {
      ingredients: newIngredients,
      ingredientsError: errorsData,
      ingredientsSum: countSum,
    };
  };

  const handleCalculateReceipt = () => {
    const { ingredients, ingredientsError, ingredientsSum } =
      handleCheckIngredients();
    const newFormData = _.cloneDeep(formData);

    if (!formData.dailyDose) {
      _.set(newFormData, "dailyDose", ingredientsSum);
    }

    if (newFormData.receiptType.value === receiptTypes[0].value) {
      const packages = newFormData.doseFreq.value * +newFormData.dosePeriod;

      if (newFormData.dailyDose < 35) {
        setErrorData({
          ...errorData,
          form: {
            value: true,
            message: `Minimalna wymagana dzienna dawka do stworzenia wywaru wynosi 35 [g]. Obecnie jest to ${newFormData.dailyDose} [g].`,
          },
        });
        return;
      }

      if (packages < 14) {
        setErrorData({
          ...errorData,
          form: {
            value: true,
            message: `Minimalna wymagana ilość paczek do stworzenia wywaru wynosi 14. Obecnie jest to ${packages} ("Liczba dni" x "Dawkowanie").`,
          },
        });
        return;
      }

      _.set(newFormData, "packages", packages);
    }

    const duplicateIngredients = _.filter(
      _.map(ingredients, (item) => item.value?.name),
      (item, index, iteratee) => _.includes(iteratee, item, index + 1)
    );

    if (!!duplicateIngredients.length) {
      const errors = {
        ...errorData,
        form: {
          value: true,
          message: `Następujące składniki powtarzają się: ${duplicateIngredients.join(
            ", "
          )}`,
        },
      };
      const ingredientsList = [];
      _.forEach(duplicateIngredients, (duplicateItem) => {
        const lastItem = _.findLast(
          ingredients,
          (ingredient) => ingredient.value?.name === duplicateItem
        );
        ingredientsList.push({
          id: lastItem.id,
          value: true,
          count: false,
        });
      });
      _.set(errors, "ingredients", ingredientsList);
      setErrorData({ ...errors });
      return;
    }

    _.set(newFormData, "ingredients", ingredients);

    currentIngredients.current = ingredients;
    setFormData({ ...newFormData });

    const { success, errors } = validate(formData, initialErrorData);

    if (success && !ingredientsError.length) {
      setErrorData({
        ...initialErrorData,
        form: { value: false, message: "" },
      });
      setPriceData(
        calculateReceipt(priceData, ingredients, !!newFormData?.doublePrepare)
      );
      setIsCalculated(true);
    } else {
      const errorsSum = { ...errors };
      if (!!ingredientsError.length) {
        _.set(errorsSum, "ingredients", ingredientsError);
      }
      setErrorData({ ...errorsSum });
    }
  };

  const handleChangePriceForm = (e, obj) => {
    const isAutocomplete = e?.target?.id?.split("-")[0] === "autocomplete";
    const newPriceData = _.cloneDeep(priceData);

    if (isAutocomplete) {
      const objName = e?.target?.id.split("-")[1];
      _.set(newPriceData, objName, obj);
    } else if (!!e?.target) {
      _.set(newPriceData, e.target.id, e.target.value);
    }

    setPriceData({
      ...calculateReceipt(
        newPriceData,
        formData.ingredients,
        !!formData?.doublePrepare
      ),
    });
  };

  const handleSubmitReceipt = async (type, url, onSuccess) => {
    setErrorPriceData({ ...initialErrorPriceData });
    const body = getReceiptData(formData, priceData);

    const { success, errors } = validate(priceData, errorPriceData);
    if (success) {
      const response = await fetchData(type, url, body);
      if (response.success) {
        onSuccess(response);
      } else {
        setErrorPriceData((prevState) => ({
          ...prevState,
          form: { value: true, message: response.message },
        }));
      }
    } else {
      setErrorPriceData({ ...errors });
    }
  };

  const setNewFormData = (data) => {
    if (!!data.ingredients) currentIngredients.current = data.ingredients;
    setFormData((prevState) => ({ ...prevState, ...data }));
  };

  return {
    formData,
    errorData,
    priceData,
    errorPriceData,
    ingredientsByType,
    setNewFormData,
    clearStates,
    handleChangeForm,
    handleAddIngredient,
    handleDeleteIngredient,
    handleCalculateReceipt,
    handleChangePriceForm,
    handleSubmitReceipt,
  };
};
