import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";

const ActiveButton = ({ label, isWaiting }) => (
  <Box sx={{ m: 1, position: "relative", width: "100%" }}>
    <Button fullWidth variant="contained" type="submit" disabled={isWaiting}>
      {label}
    </Button>
    {isWaiting && (
      <CircularProgress
        size={24}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-12px",
          marginLeft: "-12px",
        }}
      />
    )}
  </Box>
);

export default ActiveButton;
