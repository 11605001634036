import {
  contentRegex,
  emailRegex,
  passwordRegex,
} from "../../../utils/validators";

export const initialProfileData = (user) => ({
  lastName: user?.lastName || "",
  firstName: user?.firstName || "",
  email: user?.email || "",
  phoneNumber: user?.phoneNumber || "",
});

export const initialPasswordData = {
  oldPassword: "",
  newPassword1: "",
  newPassword2: "",
};

export const initialErrorProfileData = {
  lastName: { value: false, validate: contentRegex },
  firstName: { value: false, validate: contentRegex },
  phoneNumber: { value: false, validate: contentRegex },
  email: { value: false, validate: emailRegex },
  form: { value: false, message: "" },
};

export const initialErrorSettingsData = {
  commissionType: { value: false },
  commission: { value: false, validate: contentRegex },
  form: { value: false, message: "" },
};

export const initialErrorPasswordData = {
  oldPassword: { value: false, validate: passwordRegex, require: true },
  newPassword1: { value: false, validate: passwordRegex, require: true },
  newPassword2: { value: false, validate: passwordRegex, require: true },
  form: { value: false, message: "" },
};
