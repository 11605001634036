import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
      maxHeight: 100,
    },
    title: {
      display: false,
      text: "Zużycie składników",
    },
  },
};

const LineChart = ({ data }) => {
  return <Line options={options} data={data} />;
};

export default LineChart;
