import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as locales from "@mui/material/locale";
import Layout from "./layout/Layout";
import ProtectedRoute from "./utils/helpers/protectedRoute";
import { onLogout, setSettings } from "./stores/authStore";
import { publicPages } from "./utils/navItems";
import Cookies from "js-cookie";
import { fetchTypes } from "./utils/enums";
import { Endpoints } from "./utils/endpoints";
import { useLoadingState, useFetchData } from "./utils/hooks";
import { onReset } from "./stores/appStore";

import Package from "../package.json";
import { getAvailablePrivatePages } from "./utils/helpers/auth";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ae2220",
    },
  },
});

const App = () => {
  const dispatch = useDispatch();
  const { isLogin, user } = useSelector((state) => state.auth);
  const [locale, setLocale] = useState("plPL");

  const { changeDownloading } = useLoadingState();
  const { fetchData } = useFetchData({ changeDownloading });

  const privatePages = getAvailablePrivatePages(user);

  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const handleLogout = () => {
    dispatch(onLogout());
    dispatch(onReset());
  };

  const clearCacheData = () => {
    if (caches) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    window.location.reload(true);
  };

  useEffect(() => {
    if (Cookies.get("ver") !== Package.version) {
      clearCacheData();
      Cookies.set("ver", Package.version);
    }

    if (isLogin) {
      if (
        !privatePages.some((page) =>
          window.location.pathname.includes(page.path)
        )
      )
        handleLogout();
    }

    async function getInitialData() {
      const response = await fetchData(
        fetchTypes.get,
        Endpoints.settings,
        undefined,
        false
      );
      if (response.success) {
        dispatch(setSettings(response.data));
      }
    }

    getInitialData();
  }, []);

  return (
    <ThemeProvider theme={themeWithLocale}>
      <Layout>
        <Routes>
          {publicPages.map((page) => (
            <Route
              key={page.path}
              exact
              path={page.path}
              element={page.component}
            />
          ))}

          {privatePages.map(
            (page) =>
              !page.break && (
                <Route
                  key={page.path}
                  exact
                  path={page.path}
                  element={<ProtectedRoute>{page.component}</ProtectedRoute>}
                />
              )
          )}

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>
    </ThemeProvider>
  );
};

export default App;
