import React from "react";
import { useSelector } from "react-redux";
import { Table } from "../components";
import _ from "lodash";
import { ingredientAdminColumns, ingredientColumns } from "../utils/columns";

const IngredientsColumns = [
  {
    obj: "category",
    title: "Rodzina",
  },
  {
    obj: "tasteAndThermals",
    title: "Smak i termika",
  },
  {
    obj: "cycle",
    title: "Obieg",
  },
  {
    obj: "directonOfAction",
    title: "Kierunek działania",
  },
  {
    obj: "placeOfAction",
    title: "Miejsce działania",
  },
  {
    obj: "doseText",
    title: "Dawka",
  },
  {
    obj: "substitute",
    title: "Zamiennik",
  },
  {
    obj: "generalCharacteristic",
    title: "Ogólna charakterystyka",
    valueGetter: (value) =>
      !!value && (
        <div
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />
      ),
  },
  {
    obj: "contraindications",
    title: "Przeciwwskazania",
    valueGetter: (value) =>
      !!value && (
        <div
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />
      ),
  },
];

const Ingredients = () => {
  const user = useSelector((state) => state.auth.user);
  const ingredients = useSelector((state) => state.app.ingredients);
  const isAdmin = user?.isSuperuser || user?.isStaff;

  return (
    <Table
      columns={isAdmin ? ingredientAdminColumns : ingredientColumns}
      data={ingredients}
      title="Składniki"
      checking={false}
      expanding
      expandContent="Szczegóły składnika"
      expandColumns={IngredientsColumns}
    />
  );
};

export default Ingredients;
