import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";

const OrderTable = ({ data }) => {
  const columns = [
    {
      id: "code",
      numeric: false,
      label: "Kod",
    },
    {
      id: "name",
      numeric: false,
      label: "Nazwa",
    },
    // {
    //   id: "price",
    //   numeric: true,
    //   label: "Kwota",
    // },
    // {
    //   id: "priceVat",
    //   numeric: true,
    //   label: "Kwota z VAT",
    // },
    {
      id: "count",
      numeric: false,
      label: "Wymagana ilość",
    },
  ];

  return (
    <Table size="small" aria-label="expanded-table">
      <TableHead>
        <TableRow>
          {_.map(columns, (column, index) => (
            <TableCell
              key={`tableHeader-${column.id}-${index}`}
              align={column.numeric ? "right" : "left"}
              variant="head"
            >
              <Typography fontWeight={500} fontSize={12}>
                {column.label}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {data.length ? (
          _.map(data, (ingredient) => (
            <TableRow key={`tableRow-${ingredient.id}`}>
              {_.map(columns, (column, index) => (
                <TableCell
                  key={`tableCell-${column.id}-${ingredient.id}-${index}`}
                  align={column.numeric ? "right" : "left"}
                  scope="row"
                  size="small"
                >
                  <Typography fontSize={12}>{ingredient[column.id]}</Typography>
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} align="center">
              Brak danych do wyświetlenia...
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default OrderTable;
