import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardHeader,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Edit, PushPin } from "@mui/icons-material";

const Home = () => {
  const news = useSelector((state) => state.app.news);

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      {_.map(news, (newsItem) => (
        <Grid key={newsItem.id} item xs={12}>
          <Card raised sx={{ width: "100%", height: "100%" }}>
            <CardHeader
              sx={{ paddingBottom: 0 }}
              title={
                <Typography variant="h6" gutterBottom sx={{ marginBottom: 0 }}>
                  {newsItem.title}
                </Typography>
              }
              action={
                <>
                  {newsItem.sticky && (
                    <Tooltip title="Wiadomość przypięta">
                      <PushPin
                        sx={{
                          fontSize: 16,
                          marginRight: "0.5rem",
                          marginLeft: "0.5rem",
                        }}
                      />
                    </Tooltip>
                  )}
                  {newsItem.modified && (
                    <Tooltip title="Wiadomość zedytowana">
                      <Edit
                        sx={{
                          fontSize: 16,
                          marginRight: "0.5rem",
                          marginLeft: "0.5rem",
                        }}
                      />
                    </Tooltip>
                  )}
                </>
              }
            />

            <CardContent>
              <Typography component="span" variant="body2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: newsItem?.message,
                  }}
                />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Home;
