export const clientColumns = [
  {
    id: "lastName",
    numeric: false,
    label: "Nazwisko",
    width: "10%",
  },
  {
    id: "firstName",
    numeric: false,
    label: "Imię",
    width: "10%",
  },
  {
    id: "fullName",
    numeric: false,
    label: "Nazwa",
    width: "15%",
  },
  {
    id: "addressName",
    numeric: false,
    label: "Adres",
    width: "20%",
  },
  {
    id: "email",
    numeric: false,
    label: "E-mail",
    width: "15%",
  },
  {
    id: "phone",
    numeric: false,
    label: "Telefon",
    width: "10%",
  },
  {
    id: "mobile",
    numeric: false,
    label: "Telefon mobilny",
    width: "10%",
  },
  {
    id: "description",
    numeric: false,
    label: "Opis",
    width: "10%",
  },
];
