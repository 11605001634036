import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const Loading = ({ visible, sx }) => (
  <Backdrop
    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1, ...sx }}
    open={visible}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default Loading;
