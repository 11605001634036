import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  Avatar,
  Box,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Lock } from "@mui/icons-material";
import { ActiveButton } from "../../components";
import { useFetchData, useFormState, useLoadingState } from "../../utils/hooks";
import { Endpoints } from "../../utils/endpoints";
import { fetchTypes } from "../../utils/enums";
import { initialFormData, initialErrorData } from "./utlis";

const ResetPasswordConfirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const uid = query.get("uid");
  const [isReset, setIsReset] = useState(false);

  const { isWaitingForResponse, changeWaitingForResponse } = useLoadingState();
  const { fetchData } = useFetchData({ changeWaitingForResponse });
  const { formData, errorData, changeFormData, onSubmitForm } = useFormState({
    initialFormData,
    initialErrorData,
    fetchData,
  });

  useEffect(() => {
    if (!uid || !token) navigate("/");

    return () => {
      setIsReset(false);
    };
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    function onSuccess() {
      setIsReset(true);
    }

    onSubmitForm(
      fetchTypes.post,
      Endpoints.passwordResetConfirm,
      onSuccess,
      { ...formData, uid, token },
      false
    );
  };

  return (
    <>
      <Paper sx={{ maxWidth: "315px", width: "100%" }}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "2rem 3rem",
            gap: "1rem",
            alignItems: "center",
          }}
          onSubmit={onSubmit}
        >
          <Avatar sx={{ width: 56, height: 56, bgcolor: "primary.main" }}>
            <Lock sx={{ width: 28, height: 28 }} />
          </Avatar>

          {isReset ? (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="button">Zmiana hasła</Typography>
              <br />
              <Typography variant="body2" sx={{ marginTop: "1rem" }}>
                Hasło zostało zmienione. Możesz się zalogować.
              </Typography>
              <br />
              <Typography variant="caption">
                <Link href="/logowanie">Przejdź do logowania.</Link>
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant="button">Zmiana hasła</Typography>

              <TextField
                id="new_password1"
                label="Nowe hasło"
                type="password"
                value={formData["new_password1"]}
                onChange={changeFormData}
                disabled={isWaitingForResponse}
                variant="standard"
                error={errorData["new_password1"].value}
                helperText={
                  errorData["new_password1"].value && "Nieprawidłowy e-mail."
                }
              />

              <TextField
                id="new_password2"
                label="Potwierdź hasło"
                type="password"
                value={formData["new_password2"]}
                onChange={changeFormData}
                disabled={isWaitingForResponse}
                variant="standard"
                error={errorData["new_password2"].value}
                helperText={
                  errorData["new_password2"].value && "Nieprawidłowy e-mail."
                }
              />

              <Typography variant="caption">
                <Link href="/logowanie">Pamiętasz hasło? Zaloguj się.</Link>
              </Typography>

              <ActiveButton
                label={"Zmień hasło"}
                isWaiting={isWaitingForResponse}
              />

              {errorData["form"].value && (
                <Alert severity="error">
                  Wystąpił problem ze zmianą hasła! Skontaktuj się z
                  administratorem.
                </Alert>
              )}
            </>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default ResetPasswordConfirm;
