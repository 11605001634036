import { useEffect, useState } from "react";

export const useLoadingState = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);

  useEffect(() => {
    return () => {
      setIsDownloading(false);
      setIsWaitingForResponse(false);
    };
  }, []);

  const changeDownloading = (value) => {
    setIsDownloading(value);
  };

  const changeWaitingForResponse = (value) => {
    setIsWaitingForResponse(value);
  };

  return {
    isDownloading,
    changeDownloading,
    isWaitingForResponse,
    changeWaitingForResponse,
  };
};
