import { contentRegex } from "../../utils/validators";
import _ from "lodash";
import { getClientLabel } from "../../utils/helpers/app";
import { commissionTypes } from "../../utils/enums";

export const calculateOrder = (priceData, products, formData, settings) => {
  const productsCost = _.reduce(
    products,
    (acc, obj) => acc + obj.value.price * +obj.count,
    0
  );

  const productsCostVat = _.reduce(
    products,
    (acc, obj) => acc + obj.value.priceVat * +obj.count,
    0
  );

  const commissionCost =
    priceData?.commissionType?.value === 1
      ? (productsCost * +priceData.commission) / 100
      : +priceData.commission;

  const spp = commissionCost * 1.08 + productsCostVat;
  const sppp = spp;

  // if (formData?.receipt?.id) _.set(priceData, "deliveryCost", 0);
  // else _.set(priceData, "deliveryCost", settings.deliveryCost);

  _.set(priceData, "commissionPrice", _.round(commissionCost, 2));
  _.set(priceData, "ppp", _.round(productsCost, 2));
  _.set(priceData, "spp", _.round(spp, 2));
  _.set(priceData, "sppp", _.round(sppp, 2));
  _.set(priceData, "priceVat", _.round(productsCostVat, 2));

  return priceData;
};

export const hasProductError = (name, id, errorData) => {
  return _.some(
    errorData["products"],
    (product) => product[name] && product["id"] === id
  );
};

export const getOrderData = (formData, priceData) => {
  const address = formData.address.split(",");
  const deliveryAddress = {
    street: address[0]?.trim() || "(brak ulicy)",
    zipCode: address[1]?.trim() || "(brak miasta)",
    city: address[2]?.trim() || "(brak kodu pocztowego)",
  };

  const data = {
    receipt: formData?.receipt?.id || null,
    products: _.map(formData.products, (product) => ({
      ...product.value,
      count: _.toNumber(product.count),
      total: _.toNumber(product.count),
    })),
    client: formData.client?.id,
    clientName: getClientLabel({
      ...formData.client,
      address: { ...deliveryAddress },
    }),
    status: !!formData["receipt"].id ? 0 : 1,
    commision: _.toNumber(priceData.commissionPrice),
    commisionRaw: _.toNumber(priceData.commission),
    commisionType: priceData.commissionType.value,
    ppp: priceData.ppp,
    spp: priceData.spp,
    sppp: priceData.sppp,
    deliveryCost: 0,
    deliveryAddress,
    notesForClient: formData.notesForClient,
    notesForEmployee: formData.notesForEmployee,
  };

  return data;
};

export const emptyReceipt = { name: "Jako oddzielne zamówienie", id: "" };

export const initialFormData = {
  receipt: emptyReceipt,
  client: null,
  address: "",
  products: [
    { id: 0, value: null, count: "", price: "", priceVat: "" },
    { id: 1, value: null, count: "", price: "", priceVat: "" },
    { id: 2, value: null, count: "", price: "", priceVat: "" },
  ],
  notesForClient: "",
  notesForEmployee: "",
};

export const initialErrorData = {
  receipt: { value: false, required: true },
  client: { value: false, required: true },
  address: { value: false, validate: contentRegex, required: true },
  notesForClient: { value: false, validate: contentRegex },
  notesForEmployee: { value: false, validate: contentRegex },
  form: { value: false, message: "" },
};

export const initialPriceData = {
  commissionType: commissionTypes[0],
  commission: "0",
  commissionPrice: "0",
  deliveryCost: "0",
  price: "",
  priceVat: "",
};

export const initialErrorPriceData = {
  commissionType: { value: false, required: true },
  commission: { value: false, validate: contentRegex },
  form: { value: false, message: "" },
};
