import { Check } from "@mui/icons-material";
import { Avatar, Box, Link, Paper, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Wrapper } from "../components";
import { Endpoints } from "../utils/endpoints";
import { dialogTypes, fetchTypes } from "../utils/enums";
import { useLoadingState, useDialogState, useFetchData } from "../utils/hooks";

const ConfirmSignup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const [isConfirmed, setIsConfirmed] = useState(false);

  const { isDownloading, changeDownloading } = useLoadingState();
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();
  const { fetchData } = useFetchData({ changeDownloading });

  useEffect(() => {
    async function getInitialData() {
      const response = await fetchData(
        fetchTypes.get,
        `${Endpoints.confirmUser}${token}`
      );
      if (response.success) {
        setIsConfirmed(true);
      } else {
        setDialog(dialogTypes.error, response.message);
      }
    }

    if (token) getInitialData();
    else navigate("/");

    return () => {
      setIsConfirmed(false);
    };
  }, []);

  return (
    <Wrapper
      isDownloading={isDownloading}
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={setDialog}
    >
      <Paper sx={{ maxWidth: "315px", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "2rem 3rem",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ width: 56, height: 56, bgcolor: "primary.main" }}>
            <Check sx={{ width: 28, height: 28 }} />
          </Avatar>

          <Box sx={{ textAlign: "center" }}>
            <Typography variant="button">Potwierdzenie rejestracji</Typography>
            <br />
            <Typography variant="body2" sx={{ marginTop: "1rem" }}>
              {isConfirmed && !isDownloading
                ? "Adres e-mail został potwierdzony."
                : !isConfirmed && !isDownloading
                ? "Wystąpił problem z potwierdzeniem adresu e-mail. Skontaktuj się z administratorem."
                : "Potwierdzenie adresu e-mail został wysłane do serwera..."}
            </Typography>
            <br />
            <Typography variant="caption">
              <Link href="/logowanie">Przejdź do logowania.</Link>
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Wrapper>
  );
};

export default ConfirmSignup;
