import React, { useState, useEffect } from "react";
import {
  Alert,
  Avatar,
  Box,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Lock } from "@mui/icons-material";
import { ActiveButton } from "../../components";
import { initialFormData, initialErrorData } from "./utlis";
import { useLoadingState, useFetchData, useFormState } from "../../utils/hooks";
import { fetchTypes } from "../../utils/enums";
import { Endpoints } from "../../utils/endpoints";

const ResetPassword = () => {
  const [isReset, setIsReset] = useState(false);

  const { isWaitingForResponse, changeWaitingForResponse } = useLoadingState();
  const { fetchData } = useFetchData({ changeWaitingForResponse });
  const { formData, errorData, changeFormData, onSubmitForm } = useFormState({
    initialFormData,
    initialErrorData,
    fetchData,
  });

  useEffect(() => {
    return () => {
      setIsReset(false);
    };
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    function onSuccess() {
      setIsReset(true);
    }

    onSubmitForm(
      fetchTypes.post,
      Endpoints.passwordReset,
      onSuccess,
      formData,
      false
    );
  };

  return (
    <>
      <Paper sx={{ maxWidth: "315px", width: "100%" }}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "2rem 3rem",
            gap: "1rem",
            alignItems: "center",
          }}
          onSubmit={onSubmit}
        >
          <Avatar sx={{ width: 56, height: 56, bgcolor: "primary.main" }}>
            <Lock sx={{ width: 28, height: 28 }} />
          </Avatar>

          {isReset ? (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="button">Resetowanie hasła</Typography>
              <br />
              <Typography variant="body2" sx={{ marginTop: "1rem" }}>
                Na podany adres e-mail została wysłana wiadomość z
                przekierowaniem do resetu hasła.
              </Typography>
              <br />
              <Typography variant="caption">
                <Link href="/logowanie">Przejdź do logowania.</Link>
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant="button">Resetowanie hasła</Typography>

              <TextField
                id="email"
                label="Adres e-mail"
                value={formData["email"]}
                onChange={changeFormData}
                disabled={isWaitingForResponse}
                variant="standard"
                error={errorData["email"].value}
                helperText={errorData["email"].value && "Nieprawidłowy e-mail."}
              />

              <Typography variant="caption">
                <Link href="/logowanie">Pamiętasz hasło? Zaloguj się.</Link>
              </Typography>

              <ActiveButton
                label={"Zresetuj hasło"}
                isWaiting={isWaitingForResponse}
              />

              {errorData["form"].value && (
                <Alert severity="error">
                  Wystąpił problem z resetem hasła! Skontaktuj się z
                  administratorem.
                </Alert>
              )}
            </>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default ResetPassword;
