import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Table, Wrapper } from "../components";
import { onSetOrders } from "../stores/appStore";
import { useLoadingState, useDialogState, useFetchData } from "../utils/hooks";
import { Endpoints } from "../utils/endpoints";
import { dialogTypes, fetchTypes } from "../utils/enums";
import { orderColumns } from "../utils/columns";

const SentOrders = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.app.orders);

  const { isDownloading, changeDownloading } = useLoadingState();
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();
  const { fetchData } = useFetchData({ changeDownloading });

  useEffect(() => {
    async function getInitialData() {
      const response = await fetchData(
        fetchTypes.get,
        Endpoints.ordersHistoryMinified
      );
      if (response.success) {
        dispatch(onSetOrders(response.data));
      } else {
        setDialog(dialogTypes.error, response.message);
      }
    }

    getInitialData();
  }, []);

  return (
    <Wrapper
      isDownloading={isDownloading}
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={setDialog}
    >
      <Table
        columns={orderColumns}
        data={orders}
        title="Zamówienia"
        checking={false}
        expanding={true}
        expandContent="order"
        initialOrderBy="dateCreated"
      />
    </Wrapper>
  );
};

export default SentOrders;
