import _ from "lodash";

export const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
export const passwordRegex2 = new RegExp(
  /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{8,16})\S$/
);
export const nameRegex = new RegExp(/\b([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+/);
export const numberRegex = new RegExp(/^[0-9]*$/);
export const zipCodeRegex = new RegExp(/^[_0-9]{2}-[_0-9]{3}$/);
export const contentRegex = new RegExp(/(.|\s)*\S(.|\s)*/);
export const passwordRegex = new RegExp(/(.|\s)*\S(.|\s)*/);

export const validate = (formData, errorData) => {
  let success = true;
  const errors = errorData;

  const isError = (obj) => {
    if (!!formData[obj]) {
      if (_.isObject(formData[obj])) return _.isEmpty(formData[obj]);
      else if (errorData[obj]?.validate.test(formData[obj])) return false;
      else return true;
    } else {
      if (errorData[obj]?.required) return true;
      else return false;
    }
  };

  _.forEach(_.keys(formData), (obj) => {
    if (!!errorData[obj])
      if (isError(obj)) {
        if (success) success = false;
        _.set(errors, [obj, "value"], true);
      } else _.set(errors, [obj, "value"], false);
  });

  return { success, errors };
};

export const validateInput = (value, regex) => regex.test(value);
