export const receiptTypes = [
  { label: "Wywar", value: 0 },
  { label: "Granulat", value: 1 },
  { label: "Tabletki", value: 2 },
  { label: "TANG", value: 3 },
  { label: "San", value: 4 },
];

export const doseFreqTypes = [
  { label: "Raz na dwa dni", value: 0.5 },
  { label: "Raz dziennie", value: 1 },
  { label: "Dwa razy dziennie", value: 2 },
  { label: "Trzy razy dziennie", value: 3 },
];

export const commissionTypes = [
  { label: "Stała kwota", value: 0 },
  { label: "Procent", value: 1 },
];

export const receiptCategories = {
  saved: "saved",
  created: "created",
  sent: "sent",
  shipping: "shipping",
};
