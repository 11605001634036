import React from "react";
import { Popover as MUIPopover, useTheme } from "@mui/material";

const Popover = ({ children, open, anchorEl, onClose }) => {
  const theme = useTheme();

  return (
    <MUIPopover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          backgroundColor: "#f5f5f9",
          color: "rgba(0, 0, 0, 0.87)",
          fontSize: theme.typography.pxToRem(12),
          border: "1px solid #dadde9",
          maxWidth: 500,
          width: "90%",
          maxHeight: 250,
          overflow: "auto",
          padding: "0.5rem",
        },
      }}
    >
      {children}
    </MUIPopover>
  );
};

export default Popover;
