import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Wrapper } from "../../components";
import { useDialogState } from "../../utils/hooks";
import { Endpoints } from "../../utils/endpoints";
import { shippingReceiptColumns } from "../../utils/columns";
import { isPrivatePageAvailable } from "../../utils/helpers/auth";

const Shipment = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();

  useEffect(() => {
    if (!isPrivatePageAvailable(user, "/wysylka")) navigate("/");
    return () => {
      setShouldRefresh(null);
    };
  }, []);

  const handleCloseDialog = () => {
    setDialog();
  };

  const handleSetShouldRefresh = (value) => {
    setShouldRefresh(!!value);
  };

  const handleShippingItem = (id) => {
    navigate(`/wysylka/${id}`);
  };

  return (
    <Wrapper
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={handleCloseDialog}
    >
      <Grid
        columns={shippingReceiptColumns}
        url={Endpoints.receiptsToScan}
        title="Recepty do wysyłki"
        checking={false}
        expandContent="receipt"
        shouldRefresh={shouldRefresh}
        // onShippingItem={handleShippingItem}
        setRefresh={handleSetShouldRefresh}
        onRowClick={handleShippingItem}
      />
    </Wrapper>
  );
};

export default Shipment;
