import { contentRegex } from "../../utils/validators";
import { Box, Button, CircularProgress, Grid as MuiGrid } from "@mui/material";
import { Autocomplete, FormContainer, Input } from "../../components";
import _ from "lodash";
import { useEffect, useState } from "react";

export const initialFormData = {
  type: {
    value: undefined,
    label: `Wszystkie rodzaje`,
  },
  ingredient: {
    code: undefined,
    label: `Wszystkie składniki`,
  },
  from: "",
  to: "",
};

export const initialAdminFormData = {
  ...initialFormData,
  doctor: {
    pk: undefined,
    label: `Wszyscy terapeuci`,
  },
};

export const initialErrorData = {
  doctor: { value: false, required: false },
  type: { value: false, required: false },
  ingredient: { value: false, required: false },
  from: { value: false, validate: contentRegex, required: true },
  to: { value: false, validate: contentRegex, required: true },
  form: { value: false, message: "" },
};

export const SearchBar = ({
  isAdmin,
  formData,
  doctors,
  ingredients,
  changeFormData,
  isWaitingForResponse,
  errorData,
  onSubmit,
}) => {
  const [ingredientOptions, setIngredientOptions] = useState([]);

  useEffect(() => {
    const options = [
      initialFormData.ingredient,
      ..._.map(
        _.filter(ingredients, (i) => {
          if (!!formData["type"]?.value)
            return i?.code?.[0] === formData["type"]?.value;
          else return true;
        }),
        (ingredient, index) => ({
          ...ingredient,
          label: `${ingredient?.code} - ${ingredient?.name} -  ${ingredient?.nameEn}`,
        })
      ),
    ];

    setIngredientOptions(options);
  }, [formData]);

  return (
    <FormContainer title="Opcje wyszukiwania">
      {isAdmin && (
        <Autocomplete
          id="autocomplete-doctor"
          label="Terapeuta"
          value={formData["doctor"]}
          options={[
            initialAdminFormData.doctor,
            ..._.map(doctors, (doctor, index) => ({
              ...doctor,
              label: `${doctor.firstName} ${doctor.lastName}`,
            })),
          ]}
          onChange={changeFormData}
          disabled={isWaitingForResponse}
          error={errorData["doctor"].value}
          errorMessage="Wybierz terapeutę."
          gridProps={{ xs: 12, sm: 6, md: 2 }}
        />
      )}
      <Autocomplete
        id="autocomplete-type"
        label="Rodzaj składnika"
        value={formData["type"]}
        defaultValue={{
          code: "-1",
          name: `Wszystkie rodzaje`,
        }}
        options={[
          initialFormData.type,
          {
            value: "G",
            label: "Granulat / Tabletki",
          },
          {
            value: "H",
            label: "Wywar / TANG / San",
          },
        ]}
        onChange={changeFormData}
        disabled={isWaitingForResponse}
        error={errorData["type"].value}
        errorMessage="Wybierz rodzaj składnika."
        gridProps={{ xs: 12, sm: 6, md: 2 }}
      />
      <Autocomplete
        id="autocomplete-ingredient"
        label="Składnik"
        value={formData["ingredient"]}
        defaultValue={{
          code: "-1",
          name: `Wszystkie składniki`,
        }}
        options={ingredientOptions}
        onChange={changeFormData}
        disabled={isWaitingForResponse}
        error={errorData["ingredient"].value}
        errorMessage="Wybierz składnik."
        gridProps={{ xs: 12, sm: 6, md: 2 }}
      />
      <Input
        id="from"
        label="Data od"
        type="date"
        value={formData["from"]}
        onChange={changeFormData}
        disabled={isWaitingForResponse}
        error={errorData["from"].value}
        errorMessage={"Podaj datę OD."}
        gridProps={{ xs: 12, sm: 6, md: 2 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Input
        id="to"
        label="Data do"
        type="date"
        value={formData["to"]}
        onChange={changeFormData}
        disabled={isWaitingForResponse}
        error={errorData["to"].value}
        errorMessage={"Podaj datę DO."}
        gridProps={{ xs: 12, sm: 6, md: 2 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <MuiGrid item xs={12} sm={6} md={2}>
        <Box sx={{ position: "relative", width: "100%" }}>
          <Button
            variant="contained"
            fullWidth
            disabled={isWaitingForResponse}
            onClick={onSubmit}
          >
            Pokaż
          </Button>
          {isWaitingForResponse && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </MuiGrid>
    </FormContainer>
  );
};
