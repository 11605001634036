import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { AccountCircle, Logout } from "@mui/icons-material";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onLogout } from "../../stores/authStore";
import { ProfileDialog } from "./";
import { useEffect } from "react";
import { onReset } from "../../stores/appStore";
import { getAvailablePrivatePages } from "../../utils/helpers/auth";

const DrawerItems = ({ handleDrawerClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const privatePages = getAvailablePrivatePages(user);

  useEffect(() => {
    return () => {
      setIsUserDialogOpen(false);
    };
  }, []);

  const onChangePage = (url) => {
    handleDrawerClose();
    navigate(url);
  };

  const switchOpenUserDialog = () => {
    handleDrawerClose();
    setIsUserDialogOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    dispatch(onLogout());
    dispatch(onReset());
  };

  const items = _.concat(
    _.map(
      _.filter(privatePages, (i) => !i.hidden),
      (page) => ({
        ...page,
        onClick: () => onChangePage(page.path),
      })
    ),
    [
      { break: true },
      {
        title: "Profil",
        icon: <AccountCircle />,
        onClick: switchOpenUserDialog,
      },
      { title: "Wyloguj się", icon: <Logout />, onClick: handleLogout },
    ]
  );

  return (
    <>
      <ProfileDialog
        open={isUserDialogOpen}
        handleClose={switchOpenUserDialog}
      />
      <List>
        {items.map((item, index) =>
          item.break ? (
            <Divider key={index} />
          ) : (
            <ListItem key={item.title} disablePadding onClick={item.onClick}>
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </>
  );
};

export default DrawerItems;
