import { emailRegex, contentRegex } from "../../utils/validators";

export const initialFormData = {
  email: "",
  password1: "",
  password2: "",
  first_name: "",
  last_name: "",
  phone_number: "",
};

export const initialErrorData = {
  email: { value: false, validate: emailRegex, required: true },
  password1: { value: false, validate: contentRegex, required: true },
  password2: { value: false, validate: contentRegex, required: true },
  first_name: { value: false, validate: contentRegex, required: true },
  last_name: { value: false, validate: contentRegex, required: true },
  phone_number: { value: false, validate: contentRegex, required: true },
  form: { value: false },
};
