import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

export const initialFilters = {
  searchValue: "",
  order: "desc",
  orderBy: "",
  selected: [],
  selectedIds: [],
  expanded: [],
  page: 0,
  rowsPerPage: 10,
};

export const filtersStore = createSlice({
  name: "auth",
  initialState: {
    data: {
      ...initialFilters,
    },
  },
  reducers: {
    setFilters: (state, { payload }) => {
      state.data = { ...state.data, ...payload };
    },
    onCollapse: (state, { payload }) => {
      state.data.expanded = _.filter(
        state.data.expanded,
        (id) => id !== payload
      );
    },
    resetFilters: (state) => {
      state.data = { ...initialFilters };
    },
  },
});

export const { setFilters, onCollapse, resetFilters } = filtersStore.actions;

export default filtersStore.reducer;
