export const productColumns = [
  {
    id: "code",
    numeric: false,
    label: "Kod",
  },
  {
    id: "name",
    numeric: false,
    label: "Nazwa",
  },
  {
    id: "namePl",
    numeric: false,
    label: "Nazwa polska",
  },
  {
    id: "shortDescription",
    numeric: false,
    label: "Przeznaczenie",
  },
];

export const productAdminColumns = [
  {
    id: "code",
    numeric: false,
    label: "Kod",
  },
  {
    id: "name",
    numeric: false,
    label: "Nazwa",
  },
  {
    id: "namePl",
    numeric: false,
    label: "Nazwa polska",
  },
  {
    id: "shortDescription",
    numeric: false,
    label: "Przeznaczenie",
  },
  {
    id: "price",
    numeric: true,
    label: "Kwota [g/szt.]",
  },
  {
    id: "priceVat",
    numeric: true,
    label: "Kwota z VAT [g/szt.]",
  },
  {
    id: "inMagazine",
    numeric: true,
    label: "Na magazynie",
  },
];
