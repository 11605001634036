import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import {
  receiptIngredientColumns,
  savedReceiptIngredientColumns,
  shippingReceiptIngredientColumns,
} from "../utils/columns";
import { receiptCategories } from "../utils/enums";

const IngredientsTable = ({ data, type, sx }) => {
  return (
    <Box sx={{ overflow: "auto" }}>
      <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
        <Table size="small" aria-label="expanded-table" sx={sx}>
          <TableHead>
            <TableRow>
              {_.map(
                type === receiptCategories.saved
                  ? savedReceiptIngredientColumns
                  : type === receiptCategories.shipping
                  ? shippingReceiptIngredientColumns
                  : receiptIngredientColumns,
                (column, index) => (
                  <TableCell
                    key={`tableHeader-${column.id}-${index}`}
                    align={column.numeric ? "right" : "left"}
                    variant="head"
                  >
                    <Typography fontWeight={500} fontSize={12}>
                      {column.label}
                    </Typography>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.length ? (
              _.map(data, (ingredient) => (
                <TableRow key={`tableRow-${ingredient.id}`}>
                  {_.map(
                    type === receiptCategories.saved
                      ? savedReceiptIngredientColumns
                      : type === receiptCategories.shipping
                      ? shippingReceiptIngredientColumns
                      : receiptIngredientColumns,
                    (column, index) => (
                      <TableCell
                        key={`tableCell-${column.id}-${ingredient.id}-${index}`}
                        align={column.numeric ? "right" : "left"}
                        scope="row"
                        size="small"
                      >
                        <Typography fontSize={12}>
                          {column.valueGetter
                            ? column.valueGetter(ingredient[column.id])
                            : ingredient[column.id]}
                        </Typography>
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={receiptIngredientColumns.length}
                  align="center"
                >
                  Brak danych do wyświetlenia...
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default IngredientsTable;
