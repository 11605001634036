import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { IngredientsTable, FormContainer, Wrapper, BottomBar } from "./";
import {
  commissionTypes,
  receiptCategories,
  dialogTypes,
  fetchTypes,
} from "../utils/enums";
import Moment from "react-moment";
import OrderTable from "./OrderTable";
import { useLoadingState, useDialogState, useFetchData } from "../utils/hooks";
import { Endpoints } from "../utils/endpoints";
import { getIngredientsToShow } from "../utils/helpers/app";
import { useNavigate } from "react-router-dom";
import { useZxing } from "react-zxing";

const ReceiptContent = ({ data, type, shouldDownload }) => {
  const navigate = useNavigate();

  const [ingredients, setIngredients] = useState([]);
  const [receiptData, setReceiptData] = useState(null);
  const [isScanning, setIsScanning] = useState(false);

  const { isDownloading, changeDownloading } = useLoadingState();
  const { dialogMessage, dialogType, isDialogVisible, setDialog } =
    useDialogState();
  const { fetchData } = useFetchData({ changeDownloading });

  const {
    ref,
    torch: { on, off, isOn },
  } = useZxing({
    onResult(result) {
      onScan(result.getText());
    },
    paused: !isScanning,
  });

  useEffect(() => {
    async function getInitialData() {
      let response;
      if (type === receiptCategories.saved) {
        response = await fetchData(
          fetchTypes.get,
          `${Endpoints.receipts}${data?.id}/`
        );
      } else {
        response = await fetchData(
          fetchTypes.get,
          `${Endpoints.receiptsHistory}${data?.id}/`
        );
      }

      if (response.success) {
        setReceiptData(response?.data);
        if (type === receiptCategories.shipping)
          setIngredients(getIngredientsToShow(response?.data?.ingredientsPack));
        else setIngredients(getIngredientsToShow(response?.data?.ingredients));
      } else {
        setDialog(dialogTypes.error, response.message);
      }
    }

    if (shouldDownload) getInitialData();
  }, [shouldDownload]);

  useEffect(() => {
    return () => {
      setIngredients([]);
      setReceiptData(null);
      setIsScanning(false);
    };
  }, []);

  const ReceiptItem = ({ title, content }) => (
    <TableRow hover>
      <TableCell size="small" align="right" width="40%">
        <Typography fontWeight={500} fontSize={12}>
          {`${title}:`}
        </Typography>
      </TableCell>
      <TableCell size="small">
        <Typography fontSize={12}>{content}</Typography>
      </TableCell>
    </TableRow>
  );

  const toggleScanning = () => {
    setIsScanning((prevState) => !prevState);
  };

  const toggleFlash = () => {
    if (isOn) off();
    else on();
  };

  const onAcceptDialog = () => {
    setDialog();
    if (dialogType === dialogTypes.success) navigate(-1);
  };

  const onAccept = async () => {
    // if (!_.every(ingredients, (ingredient) => ingredient.isScanned)) {
    //   setDialog(
    //     dialogTypes.error,
    //     "Należy zeskanować wszystkie składniki przed zakończeniem pakowania zamówienia."
    //   );
    //   return;
    // }
    const response = await fetchData(
      fetchTypes.post,
      `${Endpoints.receiptsHistory}${data.id}/pack/`
    );
    if (response.success) {
      setDialog(dialogTypes.info, `Zmieniono status na "wysłane do klienta".`);
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  const onScan = (result) => {
    if (result) {
      const scannedIndex = _.findIndex(
        ingredients,
        (ingredient) => ingredient.barcodeText === result
      );
      const scannedIngredient = ingredients[scannedIndex];

      if (!scannedIngredient) {
        setDialog(
          dialogTypes.error,
          "W wybranej recepcie nie znaleziono zeskanowanego składnika."
        );

        return;
      } else {
        if (scannedIngredient.isScanned) {
          setDialog(dialogTypes.error, "Składnik został już zeskanowany.");

          return;
        } else {
          const newIngredients = _.map(ingredients, (ingredient, index) => {
            if (index === scannedIndex)
              return { ...ingredient, isScanned: true };
            else return ingredient;
          });

          setIngredients(newIngredients);
          setDialog(
            dialogTypes.info,
            `Należy spakować ${scannedIngredient.total} [g] ${scannedIngredient.name}.`
          );
        }
      }
    }
  };

  return (
    <Wrapper
      isDownloading={isDownloading}
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={onAcceptDialog}
    >
      <video
        ref={ref}
        style={{
          width: "100%",
          height: "calc(100vh - 73px - 64px - 2rem)",
          visibility: isScanning ? "visible" : "hidden",
          display: isScanning ? "initial" : "none",
        }}
      />

      {type === receiptCategories.shipping && (
        <BottomBar
          isCameraOn={isScanning}
          onScan={toggleScanning}
          isFlashOn={isOn}
          toggleFlash={toggleFlash}
          onAccept={onAccept}
        />
      )}
      {!isScanning && (
        <>
          <FormContainer title="Informacje o recepcie" />
          <Table sx={{ marginBottom: "3rem" }}>
            <TableBody>
              {_.map(
                type === receiptCategories.saved
                  ? savedReceiptItems
                  : type === receiptCategories.shipping
                  ? shippingReceiptItems
                  : receiptItems,
                (item, index) =>
                  (_.isArray(receiptData?.[item.obj]) &&
                    !!receiptData?.[item.obj].length) ||
                  (!_.isArray(receiptData?.[item.obj]) &&
                    (!!receiptData?.[item.obj] ||
                      _.isNumber(receiptData?.[item.obj]))) ? (
                    <ReceiptItem
                      key={`receiptItem-${item.obj}-${receiptData.id}-${index}`}
                      title={item.title}
                      content={
                        !!item.valueGetter
                          ? item.valueGetter(receiptData?.[item.obj])
                          : receiptData?.[item.obj]
                      }
                    />
                  ) : null
              )}
            </TableBody>
          </Table>

          <FormContainer title="Lista składników" />
          <IngredientsTable
            data={ingredients}
            type={type}
            sx={{
              marginBottom:
                type === receiptCategories.shipping ? "6rem" : "3rem",
            }}
          />
          {!!receiptData?.orders?.length &&
            _.map(receiptData?.orders, (order) => (
              <React.Fragment key={order.barcodeText}>
                <FormContainer title={`Zamówienie ${order.barcodeText}`} />
                <OrderTable data={_.values(order.products)} />
              </React.Fragment>
            ))}
        </>
      )}
    </Wrapper>
  );
};

const receiptItems = [
  {
    obj: "related",
    title: "Wysłano łącznie z",
    valueGetter: (value) => _.map(value, (item) => item.barcodeText).join(", "),
  },
  { obj: "barcodeText", title: "Numer zamówienia" },
  { obj: "name", title: "Nazwa recepty" },
  { obj: "description", title: "Opis recepty" },
  { obj: "receiptTypeName", title: "Rodzaj recepty" },
  {
    obj: "dailyDose",
    title: "Dzienna dawka",
    valueGetter: (value) => `${value} [g]`,
  },
  { obj: "doseFreq", title: "Dawka" },
  {
    obj: "dosePeriod",
    title: "Czas użytkownia",
    valueGetter: (value) => `${value} dzień(dni)`,
  },
  {
    obj: "doublePrepare",
    title: "Podwójne gotowanie",
    valueGetter: (value) => (!!value ? "Tak" : "Nie"),
  },
  {
    obj: "dateCreated",
    title: "Data utworzenia",
    valueGetter: (value) => <Moment format="HH:mm DD/MM/YYYY">{value}</Moment>,
  },
  {
    obj: "dateSentToClient",
    title: "Data wysłania",
    valueGetter: (value) =>
      !!value ? (
        <Moment format="HH:mm DD/MM/YYYY">{value}</Moment>
      ) : (
        "Niewysłane"
      ),
  },
  {
    obj: "commisionType",
    title: "Typ prowizja",
    valueGetter: (value) =>
      _.find(commissionTypes, (item) => item.value === value)?.label,
  },
  {
    obj: "commisionRaw",
    title: "Wartość prowizji",
  },
  {
    obj: "commision",
    title: "Kwota prowizji",
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł bez VAT`,
  },
  {
    obj: "ppp",
    title: "Koszt wyprodukowania",
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł bez VAT`,
  },
  {
    obj: "sppp",
    title: "Kwota całkowita",
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł z VAT`,
  },
  {
    obj: "notesForClient",
    title: "Notatka dla klienta",
  },
  { obj: "notesForEmployee", title: "Notatka dla pracownika" },
];

const savedReceiptItems = [
  { obj: "name", title: "Nazwa recepty" },
  { obj: "description", title: "Opis recepty" },
  { obj: "receiptTypeName", title: "Rodzaj recepty" },
];

const shippingReceiptItems = [
  {
    obj: "related",
    title: "Wysłano łącznie z",
    valueGetter: (value) => _.map(value, (item) => item.barcodeText).join(", "),
  },
  { obj: "barcodeText", title: "Numer zamówienia" },
  { obj: "clientName", title: "Dane klienta" },
  { obj: "name", title: "Nazwa recepty" },
  { obj: "description", title: "Opis recepty" },
  { obj: "receiptTypeName", title: "Rodzaj recepty" },
  {
    obj: "dailyDose",
    title: "Dzienna dawka",
    valueGetter: (value) => `${value} [g]`,
  },
  { obj: "doseFreq", title: "Dawka" },
  {
    obj: "dosePeriod",
    title: "Czas użytkownia",
    valueGetter: (value) => `${value} dzień(dni)`,
  },
  {
    obj: "doublePrepare",
    title: "Podwójne gotowanie",
    valueGetter: (value) => (!!value ? "Tak" : "Nie"),
  },
  {
    obj: "dateCreated",
    title: "Data utworzenia",
    valueGetter: (value) => <Moment format="HH:mm DD/MM/YYYY">{value}</Moment>,
  },
  {
    obj: "dateSentToClient",
    title: "Data wysłania",
    valueGetter: (value) =>
      !!value ? (
        <Moment format="HH:mm DD/MM/YYYY">{value}</Moment>
      ) : (
        "Niewysłane"
      ),
  },
  {
    obj: "notesForClient",
    title: "Notatka dla klienta",
  },
  { obj: "notesForEmployee", title: "Notatka dla pracownika" },
];

export default ReceiptContent;
