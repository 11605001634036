import React from "react";
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { AccountCircle, Password, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onLogin } from "../../stores/authStore";
import { ActiveButton } from "../../components";
import { useLoadingState, useFetchData, useFormState } from "../../utils/hooks";
import { initialFormData, initialErrorData } from "./utlis";
import { fetchTypes } from "../../utils/enums";
import { Endpoints } from "../../utils/endpoints";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settings = useSelector((state) => state.auth.settings);

  const { isWaitingForResponse, changeWaitingForResponse } = useLoadingState();
  const { fetchData } = useFetchData({ changeWaitingForResponse });
  const { formData, errorData, changeFormData, onSubmitForm } = useFormState({
    initialFormData,
    initialErrorData,
    fetchData,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    function onSuccess(response) {
      dispatch(onLogin(response.data.key));
      navigate("/");
    }

    onSubmitForm(fetchTypes.post, Endpoints.login, onSuccess, formData, false);
  };

  return (
    <>
      <Paper sx={{ maxWidth: "315px", width: "100%" }}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "2rem 3rem",
            gap: "1rem",
            alignItems: "center",
          }}
          onSubmit={onSubmit}
        >
          <Avatar sx={{ width: 56, height: 56, bgcolor: "primary.main" }}>
            <Lock sx={{ width: 28, height: 28 }} />
          </Avatar>
          <Typography variant="button">Logowanie</Typography>

          <TextField
            id="email"
            label="Adres e-mail"
            value={formData["email"]}
            onChange={changeFormData}
            disabled={isWaitingForResponse}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            variant="standard"
            error={errorData["email"].value}
            helperText={errorData["email"].value && "Nieprawidłowy e-mail."}
          />

          <TextField
            id="password"
            label="Hasło"
            value={formData["password"]}
            onChange={changeFormData}
            disabled={isWaitingForResponse}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Password />
                </InputAdornment>
              ),
            }}
            variant="standard"
            type="password"
            autoComplete="current-password"
            error={errorData["password"].value}
            helperText={errorData["password"].value && "Nieprawidłowe hasło."}
          />

          <Typography variant="caption">
            <Link href="/reset-hasla">Nie pamiętasz hasła?</Link>
          </Typography>

          <ActiveButton label="Zaloguj się" isWaiting={isWaitingForResponse} />

          {errorData["form"].value && (
            <Alert severity="error">
              {errorData.form.message || "Podane dane są błędne!"}
            </Alert>
          )}
        </Box>
      </Paper>

      {!!settings?.mainpageImage || !!settings?.mainpageMessage ? (
        <>
          <Divider
            variant="middle"
            sx={{ maxWidth: "315px", width: "100%", margin: "1rem 0" }}
          >
            <Typography variant="button">Aktualności</Typography>
          </Divider>
          <Card sx={{ maxWidth: "315px", width: "100%", marginBottom: "1rem" }}>
            {!!settings.mainpageImage && (
              <CardMedia
                component="img"
                image={settings.mainpageImage}
                alt="news photo"
                sx={{ width: "100%", height: "auto" }}
              />
            )}
            {!!settings.mainpageMessage && (
              <CardContent>
                <Typography component="span" variant="body2">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: settings.mainpageMessage,
                    }}
                  />
                </Typography>
              </CardContent>
            )}
          </Card>
        </>
      ) : null}
    </>
  );
};

export default Login;
