import React from "react";
import _ from "lodash";
import {
  Box,
  Table as MuiTable,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
import { TableBody, TableHeader, TableToolbar } from "./components";
import { useTableManagement } from "../../utils/hooks";

const Table = ({
  data,
  columns,
  initialOrder,
  initialOrderBy,
  type,
  title,
  checking = true,
  toolbarActions,
  expanding = false,
  expandContent,
  expandColumns,
  onAcceptItem,
  onEditItem,
  onDeleteItem,
  onCopyItem,
  deleteIcon,
  deleteTitle,
  clear,
  footer = null,
}) => {
  const {
    rows,
    searchValue,
    order,
    orderBy,
    selected,
    selectedIds,
    expanded,
    page,
    rowsPerPage,
    setExpanded,
    handleChangeSort,
    handleSelectAllClick,
    handleClick,
    handleExpandRow,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSearchValue,
  } = useTableManagement({ data, columns, initialOrder, initialOrderBy });

  const handleSetActiveItem = (item, type) => {
    if (type === "accept") onAcceptItem(item);
    else if (type === "delete") onDeleteItem(item);
    else if (type === "edit") {
      onEditItem(item);
      setExpanded((prevState) => _.filter(prevState, (id) => id !== item.id));
    } else if (type === "copy") onCopyItem(item);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          mb: 2,
          display: "flex",
          flexDirection: "column",
        }}
        elevation={clear ? 0 : 1}
      >
        <TableToolbar
          numSelected={selectedIds.length}
          title={title}
          searchValue={searchValue}
          changeSearchValue={handleChangeSearchValue}
          toolbarActions={toolbarActions}
          clear={clear}
        />
        <TableContainer
          sx={{
            height: "100%",
            maxHeight: "calc(100vh - 2rem - 205px)",
          }}
        >
          <MuiTable sx={{ minWidth: 750 }} size={"medium"} stickyHeader>
            <TableHeader
              numSelected={selectedIds.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleChangeSort}
              rowCount={rows.length}
              columns={columns}
              checking={checking}
              expanding={expanding}
              isAccept={!!onAcceptItem}
              isEdit={!!onEditItem}
              isDelete={!!onDeleteItem}
              isCopy={!!onCopyItem}
            />
            <TableBody
              type={type}
              columns={columns}
              checking={checking}
              expanding={expanding}
              rows={rows}
              order={order}
              orderBy={orderBy}
              selected={selectedIds}
              expanded={expanded}
              page={page}
              rowsPerPage={rowsPerPage}
              handleClick={handleClick}
              handleExpandRow={handleExpandRow}
              expandContent={expandContent}
              expandColumns={expandColumns}
              isAccept={!!onAcceptItem}
              isEdit={!!onEditItem}
              isDelete={!!onDeleteItem}
              isCopy={!!onCopyItem}
              deleteTitle={deleteTitle}
              deleteIcon={deleteIcon}
              onSetActiveItem={handleSetActiveItem}
            />
          </MuiTable>
        </TableContainer>
        {footer}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ marginTop: "auto", minHeight: "52px", height: "auto" }}
          labelRowsPerPage="Wierszy:"
        />
      </Paper>
    </Box>
  );
};

export default Table;
