import React from "react";
import { useSelector } from "react-redux";
import { Table } from "../components";
import { productAdminColumns, productColumns } from "../utils/columns";

const ProductColumns = [
  {
    obj: "description",
    title: "Opis",
    valueGetter: (value) =>
      !!value && (
        <div
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />
      ),
  },
];

const Products = () => {
  const user = useSelector((state) => state.auth.user);
  const products = useSelector((state) => state.app.products);
  const isAdmin = user?.isSuperuser || user?.isStaff;

  return (
    <Table
      columns={isAdmin ? productAdminColumns : productColumns}
      data={products}
      title="Produkty"
      checking={false}
      expanding
      expandContent="Szczegóły produktu"
      expandColumns={ProductColumns}
    />
  );
};

export default Products;
