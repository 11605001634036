import React from "react";
import {
  Drawer as MuiDrawer,
  Divider,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  Typography,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { DrawerItems } from "./";

const Drawer = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <MuiDrawer
      anchor="right"
      open={open}
      onClose={handleDrawerClose}
      ModalProps={{ keepMounted: true }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap="1rem"
        height="73px"
        padding="0.5rem 0.5rem"
      >
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
        <Typography variant="button">Menu</Typography>
      </Box>
      <Divider />
      <DrawerItems handleDrawerClose={handleDrawerClose} />
    </MuiDrawer>
  );
};

export default Drawer;
