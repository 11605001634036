import React from "react";
import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/logo-light.png";

const AppBar = ({ open, handleDrawerOpen }) => {
  return (
    <MuiAppBar position="static" sx={{ height: "73px" }}>
      <Toolbar>
        <Typography
          variant="h6"
          noWrap
          sx={{
            flexGrow: 1,
            padding: "0.5rem 0px",
          }}
          component="div"
        >
          <img src={Logo} style={{ height: "48px" }} />
        </Typography>

        <IconButton
          color="inherit"
          edge="end"
          onClick={handleDrawerOpen}
          sx={{ ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
