import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "react-moment";
import { OrderTable, Wrapper, Progress, FormContainer } from "./";
import { useLoadingState, useDialogState, useFetchData } from "../utils/hooks";
import { Endpoints } from "../utils/endpoints";
import { dialogTypes, fetchTypes, commissionTypes } from "../utils/enums";

const OrderContent = ({ data, shouldDownload }) => {
  const [orderData, setOrderData] = useState(null);

  const { isDownloading, changeDownloading } = useLoadingState();
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();
  const { fetchData } = useFetchData({ changeDownloading });

  useEffect(() => {
    async function getInitialData() {
      const response = await fetchData(
        fetchTypes.get,
        `${Endpoints.ordersHistory}${data?.id}/`
      );
      if (response.success) {
        setOrderData(response.data);
      } else {
        setDialog(dialogTypes.error, response.message);
      }
    }

    if (shouldDownload) getInitialData();
  }, [shouldDownload]);

  useEffect(() => {
    return () => {
      setOrderData(null);
    };
  }, []);

  const ReceiptItem = ({ title, content }) => (
    <TableRow hover>
      <TableCell size="small" align="right" width="40%">
        <Typography fontWeight={500} fontSize={12}>
          {`${title}:`}
        </Typography>
      </TableCell>
      <TableCell size="small">
        <Typography fontSize={12}>{content}</Typography>
      </TableCell>
    </TableRow>
  );

  return (
    <Wrapper
      isDownloading={isDownloading}
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={setDialog}
    >
      <FormContainer title="Informacje o zamówieniu" />
      <Table sx={{ marginBottom: 2 }}>
        <TableBody>
          {_.map(orderItems, (item, index) =>
            !!data?.[item.obj] || _.isNumber(data?.[item.obj]) ? (
              <ReceiptItem
                key={`orderItem-${item.obj}-${data.id}-${index}`}
                title={item.title}
                content={
                  !!item.valueGetter
                    ? item.valueGetter(data?.[item.obj])
                    : data?.[item.obj]
                }
              />
            ) : null
          )}
        </TableBody>
      </Table>

      <FormContainer title="Lista produktów" />

      <OrderTable data={_.values(orderData?.products)} />
    </Wrapper>
  );
};

const orderItems = [
  { obj: "barcodeText", title: "Numer zamówienia" },
  {
    obj: "dateCreated",
    title: "Wysłane",
    valueGetter: (value) => <Moment format="HH:mm DD/MM/YYYY">{value}</Moment>,
  },
  {
    obj: "dateSentToClient",
    title: "Data wysłania",
    valueGetter: (value) =>
      !!value ? (
        <Moment format="HH:mm DD/MM/YYYY">{value}</Moment>
      ) : (
        "Niewysłane"
      ),
  },
  {
    obj: "commisionType",
    title: "Typ prowizja",
    valueGetter: (value) =>
      _.find(commissionTypes, (item) => item.value === value)?.label,
  },
  {
    obj: "commisionRaw",
    title: "Wartość prowizji",
  },
  {
    obj: "commision",
    title: "Kwota prowizji",
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł bez VAT`,
  },
  {
    obj: "ppp",
    title: "Koszt wyprodukowania",
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł bez VAT`,
  },
  {
    obj: "sppp",
    title: "Kwota całkowita",
    valueGetter: (value) => `${_.round(value, 2).toFixed(2)} zł z VAT`,
  },
  {
    obj: "notesForClient",
    title: "Notatka dla klienta",
  },
  { obj: "notesForEmployee", title: "Notatka dla pakowania" },
];

export default OrderContent;
