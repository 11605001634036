import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  Avatar,
  Box,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { AppRegistration, Check } from "@mui/icons-material";
import { ActiveButton } from "../../components";
import { useLoadingState, useFetchData, useFormState } from "../../utils/hooks";
import { fetchTypes } from "../../utils/enums";
import { Endpoints } from "../../utils/endpoints";
import { initialFormData, initialErrorData } from "./utils";

const Signup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const [isSignedUp, setIsSignedUp] = useState(false);

  const { isWaitingForResponse, changeWaitingForResponse } = useLoadingState();
  const { fetchData } = useFetchData({ changeWaitingForResponse });
  const { formData, errorData, changeFormData, onSubmitForm, setErrorData } =
    useFormState({
      initialFormData,
      initialErrorData,
      fetchData,
    });

  useEffect(() => {
    if (!token) navigate("/");

    return () => {
      setIsSignedUp(false);
    };
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const body = { invitationCode: token, ...formData };

    if (body.password1 !== body.password2) {
      setErrorData({
        ...errorData,
        form: { value: true, message: "Podane hasła są różne." },
        password2: { ...errorData.password2, value: true },
      });

      return;
    }

    function onSuccess() {
      setIsSignedUp(true);
    }

    onSubmitForm(fetchTypes.post, Endpoints.register, onSuccess, body, false);
  };

  return (
    <>
      <Paper sx={{ maxWidth: "315px", width: "100%" }}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "2rem 3rem",
            gap: "1rem",
            alignItems: "center",
          }}
          onSubmit={onSubmit}
        >
          <Avatar sx={{ width: 56, height: 56, bgcolor: "primary.main" }}>
            {isSignedUp ? (
              <Check sx={{ width: 28, height: 28 }} />
            ) : (
              <AppRegistration sx={{ width: 28, height: 28 }} />
            )}
          </Avatar>

          {isSignedUp ? (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="button">
                Konto zostało zarejestrowane
              </Typography>
              <br />
              <Typography variant="body2" sx={{ marginTop: "1rem" }}>
                Można teraz zalogować się do utworzonego konta.
              </Typography>
              <br />
              <Typography variant="caption">
                <Link href="/logowanie">Przejdź do logowania.</Link>
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant="button">Rejestracja</Typography>

              <TextField
                id="email"
                label="Adres e-mail"
                value={formData["email"]}
                onChange={changeFormData}
                disabled={isWaitingForResponse}
                variant="standard"
                error={errorData["email"].value}
                helperText={errorData["email"].value && "Nieprawidłowy e-mail."}
              />

              <TextField
                id="password1"
                label="Hasło"
                value={formData["password1"]}
                onChange={changeFormData}
                disabled={isWaitingForResponse}
                variant="standard"
                type="password"
                error={errorData["password1"].value}
                helperText={
                  errorData["password1"].value &&
                  "Niepoprawne hasło (powinno zawierać co najmniej 8 znaków, wielką i małą literę, minimum jedną cyfrę i jeden znak specjalny)."
                }
              />

              <TextField
                id="password2"
                label="Potwierdź hasło"
                value={formData["password2"]}
                onChange={changeFormData}
                disabled={isWaitingForResponse}
                variant="standard"
                type="password"
                error={errorData["password2"].value}
                helperText={
                  errorData["password2"].value && "Nieprawidłowe hasło."
                }
              />

              <TextField
                id="first_name"
                label="Imię"
                value={formData["first_name"]}
                onChange={changeFormData}
                disabled={isWaitingForResponse}
                variant="standard"
                error={errorData["first_name"].value}
                helperText={
                  errorData["first_name"].value && "Nieprawidłowe imię."
                }
              />

              <TextField
                id="last_name"
                label="Nazwisko"
                value={formData["last_name"]}
                onChange={changeFormData}
                disabled={isWaitingForResponse}
                variant="standard"
                error={errorData["last_name"].value}
                helperText={
                  errorData["last_name"].value && "Nieprawidłowe nazwisko."
                }
              />

              <TextField
                id="phone_number"
                label="Telefon"
                value={formData["phone_number"]}
                onChange={changeFormData}
                disabled={isWaitingForResponse}
                variant="standard"
                error={errorData["phone_number"].value}
                helperText={
                  errorData["phone_number"].value &&
                  "Nieprawidłowy numer telefonu."
                }
              />

              <Typography variant="caption">
                <Link href="/logowanie">Masz już konto? Zaloguj się.</Link>
              </Typography>

              <ActiveButton
                label={"Zarejestruj konto"}
                isWaiting={isWaitingForResponse}
              />

              {errorData["form"].value && (
                <Alert severity="error">
                  {errorData.form.message ||
                    "Wystąpił problem z rejestracją konta! Skontaktuj się z administratorem."}
                </Alert>
              )}
            </>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default Signup;
