import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import { Table, Wrapper } from "../components";
import { onSetCopiedItem, onSetSavedReceipts } from "../stores/appStore";
import { receiptCategories, dialogTypes, fetchTypes } from "../utils/enums";
import { savedReceiptColumns } from "../utils/columns";
import { Endpoints } from "../utils/endpoints";
import { useLoadingState, useDialogState, useFetchData } from "../utils/hooks";
import { useNavigate } from "react-router-dom";

const SavedReceipts = ({ isDialog = false, handleChooseItem }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const savedReceipts = useSelector((state) => state.app.savedReceipts);

  const { isDownloading, changeDownloading } = useLoadingState();
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();
  const { fetchData } = useFetchData({ changeDownloading });

  useEffect(() => {
    async function getInitialData() {
      const response = await fetchData(
        fetchTypes.get,
        `${Endpoints.receiptsMinified}?status=0`
      );
      if (response.success) {
        dispatch(onSetSavedReceipts(response.data));
      } else {
        setDialog(dialogTypes.error, response.message);
      }
    }

    if (!savedReceipts.length) getInitialData();
  }, []);

  const handleCopyReceipt = async (item) => {
    const response = await fetchData(
      fetchTypes.get,
      `${Endpoints.receipts}${item.id}/`
    );
    if (response.success) {
      dispatch(onSetCopiedItem(response.data));
      setTimeout(() => {
        navigate("/nowa-recepta");
      }, 500);
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  return (
    <Wrapper
      isDownloading={isDownloading}
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={setDialog}
    >
      <Table
        type={receiptCategories.saved}
        columns={savedReceiptColumns}
        data={savedReceipts}
        title="Przykładowe recepty"
        checking={false}
        expanding
        expandContent="receipt"
        clear={isDialog}
        onDeleteItem={isDialog && handleChooseItem}
        deleteTitle={isDialog && "Wybierz receptę"}
        deleteIcon={isDialog && <ContentPasteGoIcon fontSize="small" />}
        onCopyItem={!isDialog && handleCopyReceipt}
      />
    </Wrapper>
  );
};

export default SavedReceipts;
