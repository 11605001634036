import {
  commissionTypes,
  dialogTypes,
  doseFreqTypes,
  receiptTypes,
} from "../../utils/enums";
import { contentRegex } from "../../utils/validators";
import _ from "lodash";
import {
  getClientAddress,
  getClientLabel,
  getIngredientLabel,
  getIngredients,
} from "../../utils/helpers/app";

export const hasIngredientError = (name, id, errorData) => {
  return _.some(
    errorData["ingredients"],
    (ingredient) => ingredient[name] && ingredient["id"] === id
  );
};

export const calculateReceipt = (priceData, ingredients, isDoublePrepare) => {
  const ingredientsCost = _.reduce(
    ingredients,
    (acc, obj) => acc + +obj.value?.price * +obj.dose * +obj.dosePeriod,
    0
  );

  const doublePrepare = isDoublePrepare ? 30 : 0;

  const commissionCost =
    priceData?.commissionType?.value === 1
      ? (ingredientsCost * +priceData.commission) / 100
      : +priceData.commission;

  const spp = (commissionCost + ingredientsCost) * 1.08 + doublePrepare;
  const sppp = spp;

  _.set(priceData, "commissionPrice", _.round(commissionCost || 0, 2));
  _.set(priceData, "ppp", _.round(ingredientsCost || 0, 2));
  _.set(priceData, "spp", _.round(spp || 0, 2));
  _.set(priceData, "sppp", _.round(sppp || 0, 2));
  if (!priceData.commissionType)
    _.set(priceData, "commissionType", commissionTypes[0]);

  return priceData;
};

export const getReceiptData = (formData, priceData) => {
  const address = formData.address.split(",");
  const deliveryAddress = {
    street: address[0]?.trim() || "(brak ulicy)",
    zipCode: address[1]?.trim() || "(brak miasta)",
    city: address[2]?.trim() || "(brak kodu pocztowego)",
  };

  const data = {
    name: formData.name,
    receiptType: formData.receiptType?.value,
    description: formData.description,
    ingredients: _.map(formData.ingredients, (ingredient) => ({
      ...ingredient.value,
      dose: _.toNumber(ingredient.dose),
      count: _.toNumber(ingredient.count),
      total: _.toNumber(ingredient.total),
    })),
    client: formData.client?.id,
    clientName: getClientLabel({
      ...formData.client,
      address: { ...deliveryAddress },
    }),
    status: 0,
    commision: _.toNumber(priceData.commissionPrice),
    commisionRaw: _.toNumber(priceData.commission),
    commisionType: priceData.commissionType.value,
    ppp: priceData.ppp,
    spp: priceData.spp,
    sppp: priceData.sppp,
    deliveryCost: 0,
    deliveryAddress,
    dailyDose: _.toNumber(formData.dailyDose),
    dosePeriod: _.toNumber(formData.dosePeriod),
    doseFreq: formData.doseFreq?.label,
    notesForClient: formData.notesForClient,
    notesForEmployee: formData.notesForEmployee,
    doublePrepare: !!formData?.doublePrepare,
  };

  return data;
};

export const setReceiptData = (editItem, ingredients) => {
  const newFormData = {
    client: editItem?.client || null,
    address: !!editItem?.deliveryAddress
      ? getClientAddress(editItem.deliveryAddress)
      : "",
    receiptType:
      _.find(receiptTypes, (type) => type.value === editItem?.receiptType) ||
      receiptTypes[0],
    dailyDose: editItem?.dailyDose || "",
    dosePeriod: editItem?.dosePeriod || "",
    doseFreq:
      _.find(doseFreqTypes, (type) => type.label === editItem?.doseFreq) ||
      doseFreqTypes[0],
    ingredients: getIngredients(editItem?.ingredients, ingredients),
    name: editItem?.name || "",
    description: editItem?.description || "",
    notesForClient: editItem?.notesForClient || "",
    notesForEmployee: editItem?.notesForEmployee || "",
    doublePrepare: !!editItem?.doublePrepare,
  };

  const newPriceData = {
    commission: editItem?.commisionRaw,
    commissionType: _.find(
      commissionTypes,
      (item) => item.value === editItem?.commisionType
    ),
    deliveryCost: "0",
  };

  return {
    newFormData,
    newPriceData: calculateReceipt(
      newPriceData,
      newFormData.ingredients,
      !!newFormData?.doublePrepare
    ),
  };
};

export const getIngredientsByType = (ingredients, type) => {
  switch (type.label) {
    case receiptTypes[1].label:
    case receiptTypes[2].label:
      return _.filter(
        ingredients,
        (ingredient) => _.toUpper(ingredient.code[0]) === "G"
      );
    case receiptTypes[0].label:
    case receiptTypes[3].label:
    case receiptTypes[4].label:
      return _.filter(
        ingredients,
        (ingredient) => _.toUpper(ingredient.code[0]) === "H"
      );
    default:
      return ingredients;
  }
};

export const changeIngredientsByType = (
  ingredients,
  type,
  ingredientsList,
  setDialog
) => {
  let newIngredients = _.cloneDeep(ingredients);
  if (!ingredientsList?.length) return newIngredients;

  switch (type.value) {
    case receiptTypes[1].value:
    case receiptTypes[2].value:
      if (_.toUpper(ingredients[0]?.value?.code[0]) === "H") {
        const noIngredients = [];
        const filteredIngredients = ingredients.filter((item) => {
          const isExist = _.find(
            ingredientsList,
            (ingredientItem) =>
              _.toUpper(ingredientItem.code) ===
              `G${_.toUpper(item.value?.code?.substring(1))}`
          );

          if (!isExist) noIngredients.push(item.value);

          return !!isExist;
        });

        if (noIngredients.length)
          setDialog(
            dialogTypes.error,
            `Nie znaleziono odpowiednika dla: ${noIngredients
              .map((item) => getIngredientLabel(item))
              .join(", ")}`
          );

        newIngredients = _.map(filteredIngredients, (ingredient, index) => {
          const newIngredient = _.find(
            ingredientsList,
            (ingredientItem) =>
              _.toUpper(ingredientItem.code) ===
              `G${_.toUpper(ingredient.value?.code?.substring(1))}`
          );

          return {
            ...ingredient,
            id: index,
            value: newIngredient,
          };
        });
      }
      break;
    case receiptTypes[0].value:
    case receiptTypes[3].value:
    case receiptTypes[4].value:
      if (_.toUpper(ingredients[0]?.value?.code[0]) === "G") {
        const noIngredients = [];
        const filteredIngredients = ingredients.filter((item) => {
          const isExist = _.find(
            ingredientsList,
            (ingredientItem) =>
              _.toUpper(ingredientItem.code) ===
              `H${_.toUpper(item.value?.code?.substring(1))}`
          );

          if (!isExist) noIngredients.push(item.value);

          return !!isExist;
        });

        if (noIngredients.length)
          setDialog(
            dialogTypes.error,
            `Nie znaleziono odpowiednika dla: ${noIngredients
              .map((item) => getIngredientLabel(item))
              .join(", ")}`
          );

        newIngredients = _.map(filteredIngredients, (ingredient, index) => {
          const newIngredient = _.find(
            ingredientsList,
            (ingredientItem) =>
              _.toUpper(ingredientItem.code) ===
              `H${_.toUpper(ingredient.value?.code?.substring(1))}`
          );

          return {
            ...ingredient,
            id: index,
            value: newIngredient,
          };
        });
      }
      break;
    default:
      break;
  }

  return newIngredients;
};

export const initialFormData = {
  client: null,
  address: "",
  receiptType: receiptTypes[0],
  dailyDose: "",
  dosePeriod: "",
  doseFreq: doseFreqTypes[0],
  ingredients: [
    { id: 0, value: null, count: "", dose: "", total: "", totalPrice: "" },
    { id: 1, value: null, count: "", dose: "", total: "", totalPrice: "" },
    { id: 2, value: null, count: "", dose: "", total: "", totalPrice: "" },
  ],
  name: "",
  description: "",
  notesForClient: "",
  notesForEmployee: "",
  doublePrepare: false,
};

export const initialErrorData = {
  client: { value: false, required: true },
  address: { value: false, validate: contentRegex, required: true },
  receiptType: { value: false, required: true },
  dailyDose: { value: false, validate: contentRegex, required: true },
  dosePeriod: { value: false, validate: contentRegex, required: true },
  doseFreq: { value: false, required: true },
  name: { value: false, validate: contentRegex },
  description: { value: false, validate: contentRegex },
  notesForClient: { value: false, validate: contentRegex },
  notesForEmployee: { value: false, validate: contentRegex },
  form: { value: false, message: "" },
};

export const initialPriceData = {
  commissionType: commissionTypes[0],
  commission: "0",
  commissionPrice: "",
  deliveryCost: "0",
  ppp: "",
  spp: "",
  sppp: "",
};

export const initialErrorPriceData = {
  commissionType: { value: false, required: true },
  commission: { value: false, validate: contentRegex },
  form: { value: false, message: "" },
};
