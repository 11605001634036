import { Grid, TextField } from "@mui/material";
import React from "react";

const Input = ({ error, errorMessage, gridProps, ...rest }) => {
  return (
    <Grid item {...gridProps}>
      <TextField
        size="small"
        fullWidth
        error={error}
        helperText={error && errorMessage}
        onWheelCapture={(e) => {
          e.target.blur();
        }}
        {...rest}
      />
    </Grid>
  );
};

export default Input;
