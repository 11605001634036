import { createSlice } from "@reduxjs/toolkit";

export const appStore = createSlice({
  name: "app",
  initialState: {
    news: [],
    ingredients: [],
    ingredientCategories: [],
    clients: [],
    savedReceipts: [],
    products: [],
    orders: [],
    doctors: [],
    copiedItem: null,
  },
  reducers: {
    onReset: (state, {}) => {
      state.news = [];
      state.ingredients = [];
      state.clients = [];
      state.savedReceipts = [];
      state.products = [];
      state.orders = [];
      state.doctors = [];
      state.copiedItem = null;
    },
    onSetNews: (state, { payload }) => {
      state.news = payload;
    },
    onSetIngredients: (state, { payload }) => {
      state.ingredients = payload;
    },
    onSetClients: (state, { payload }) => {
      state.clients = payload;
    },
    onAddClient: (state, { payload }) => {
      state.clients.push(payload);
    },
    onEditClient: (state, { payload }) => {
      state.clients = state.clients.map((item) =>
        item.id === payload.id ? payload : item
      );
    },
    onDeleteClient: (state, { payload }) => {
      state.clients = state.clients.filter(({ id }) => id !== payload);
    },
    onSetSavedReceipts: (state, { payload }) => {
      state.savedReceipts = payload;
    },
    onSetProducts: (state, { payload }) => {
      state.products = payload;
    },
    onSetOrders: (state, { payload }) => {
      state.orders = payload;
    },
    onSetDoctors: (state, { payload }) => {
      state.doctors = payload;
    },
    onSetCopiedItem: (state, { payload }) => {
      state.copiedItem = payload;
    },
    onSetIngredientCategories: (state, { payload }) => {
      state.ingredientCategories = [{ id: -1, name: "Pozostałe" }, ...payload];
    },
  },
});

export const {
  onReset,
  onSetNews,
  onSetIngredients,
  onSetClients,
  onAddClient,
  onEditClient,
  onDeleteClient,
  onSetSavedReceipts,
  onSetProducts,
  onSetOrders,
  onSetDoctors,
  onSetCopiedItem,
  onSetIngredientCategories,
} = appStore.actions;

export default appStore.reducer;
