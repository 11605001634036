import { passwordRegex } from "../../utils/validators";

export const initialFormData = {
  new_password1: "",
  new_password2: "",
};
export const initialErrorData = {
  new_password1: { value: false, validate: passwordRegex, required: true },
  new_password2: { value: false, validate: passwordRegex, required: true },
  form: { value: false },
};
