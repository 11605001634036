import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "top",
      maxHeight: 100,
    },
    title: {
      display: false,
      text: "Zużycie składników",
    },
  },
};

const PieChart = ({ data }) => {
  return (
    <div style={{ height: "500px" }}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChart;
