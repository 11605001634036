import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { commissionTypes } from "../utils/enums";
import Cookies from "js-cookie";

export const authStore = createSlice({
  name: "auth",
  initialState: {
    isLogin: !!Cookies.get("token"),
    token: Cookies.get("token"),
    user: null,
    settings: {
      commissionType: commissionTypes[0],
      commission: "0",
      deliveryCost: "0",
    },
  },
  reducers: {
    onLogin: (state, { payload }) => {
      state.isLogin = true;
      Cookies.set("token", payload);
      state.token = payload;
    },
    onLogout: (state) => {
      state.isLogin = false;
      Cookies.remove("token");
      state.token = "";
      state.user = null;
      state.settings = {
        commissionType: commissionTypes[0],
        commission: "0",
        deliveryCost: "0",
      };
    },
    setUser: (state, { payload }) => {
      state.user = payload;

      const settingsData = {
        commissionType: payload?.commisionType
          ? _.find(
              commissionTypes,
              (item) => item.value === payload.commisionType
            )
          : commissionTypes[0],
        commission: payload?.commisionRaw || "",
      };
      state.settings = { ...state.settings, ...settingsData };
    },
    setSettings: (state, { payload }) => {
      state.settings = { ...state.settings, ...payload };
    },
  },
});

export const { onLogin, onLogout, setUser, setSettings } = authStore.actions;

export default authStore.reducer;
