import { Box, Paper, Typography } from "@mui/material";
import moment from "moment";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components";
import { onSetDoctors } from "../../stores/appStore";
import {
  commissionDocumentTypes,
  initialFormData,
  initialAdminFormData,
  initialErrorData,
  SearchBar,
  CommissionTable,
} from "./utlis";
import {
  useDialogState,
  useFetchData,
  useFormState,
  useLoadingState,
} from "../../utils/hooks";
import { Endpoints } from "../../utils/endpoints";
import { dialogTypes, fetchTypes } from "../../utils/enums";

const Commissions = () => {
  const date = moment();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const doctors = useSelector((state) => state.app.doctors);
  const [data, setData] = useState(null);
  const [url, setUrl] = useState("");
  const isAdmin = user?.isSuperuser || user?.isStaff;

  const {
    isDownloading,
    changeDownloading,
    isWaitingForResponse,
    changeWaitingForResponse,
  } = useLoadingState();
  const { fetchData } = useFetchData({
    changeDownloading,
    changeWaitingForResponse,
  });
  const { formData, errorData, changeFormData, onSubmitForm, setFormData } =
    useFormState({
      initialFormData: isAdmin ? initialAdminFormData : initialFormData,
      initialErrorData,
      fetchData,
    });
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();

  useEffect(() => {
    async function getInitialData() {
      const response = await fetchData(fetchTypes.get, Endpoints.doctors);
      if (response.success) {
        dispatch(onSetDoctors(response.data));
      } else {
        setDialog(dialogTypes.error, response.message);
      }
    }

    if (isAdmin && !doctors.length) getInitialData();

    setFormData((prevState) => ({
      ...prevState,
      from: date.startOf("month").format("YYYY-MM-DD"),
      to: date.endOf("month").format("YYYY-MM-DD"),
    }));

    return () => {
      setData(null);
    };
  }, [isAdmin]);

  const onSubmit = async () => {
    setUrl("");
    function onSuccess(response) {
      setData({
        ...response.data,
        title:
          formData?.type?.label === commissionDocumentTypes[1].label
            ? "Zamówienia"
            : "Recepty",
      });
    }

    if (formData?.type?.label === commissionDocumentTypes[1].label) {
      let url = `${Endpoints.commisionOrders}?date_from=${formData.from}&date_to=${formData.to}`;
      if (!isAdmin) url += `&doctor=${user.id}`;
      else if (!!formData?.doctor?.pk) url += `&doctor=${formData.doctor.pk}`;

      onSubmitForm(fetchTypes.get, url, onSuccess);
      setUrl(url);
    } else {
      let url = `${Endpoints.commisionReceipts}?date_from=${formData.from}&date_to=${formData.to}`;
      if (!isAdmin) url += `&doctor=${user.id}`;
      else if (!!formData?.doctor?.pk) url += `&doctor=${formData.doctor.pk}`;

      onSubmitForm(fetchTypes.get, url, onSuccess);
      setUrl(url);
    }
  };

  return (
    <Wrapper
      isDownloading={isDownloading}
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={setDialog}
    >
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", pl: "16px" }}>
          <Box
            minHeight={64}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Typography sx={{ flex: "1 1" }} variant="h6" component="div">
              Przegląd kosztów
            </Typography>
          </Box>
          <SearchBar
            isAdmin={isAdmin}
            formData={formData}
            doctors={[
              {
                pk: undefined,
                firstName: `Wszyscy`,
                lastName: `terapeuci`,
              },
              ...doctors,
            ]}
            changeFormData={changeFormData}
            isWaitingForResponse={isWaitingForResponse}
            errorData={errorData}
            onSubmit={onSubmit}
          />

          {!!data && <CommissionTable data={data} url={url} />}
        </Paper>
      </Box>
    </Wrapper>
  );
};

export default Commissions;
