import React, { useEffect, useState } from "react";
import { Alert, Box, Button } from "@mui/material";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Dialog, Wrapper } from "../../components";
import { receiptTypes, dialogTypes, fetchTypes } from "../../utils/enums";
import { SavedReceipts } from "../";
import {
  useDialogState,
  useFetchData,
  useLoadingState,
  useReceiptManagement,
} from "../../utils/hooks";
import { Endpoints } from "../../utils/endpoints";
import { ReceiptForm, ReceiptPricesForm } from "./components";

const NewReceipt = ({
  activeItem,
  handleSuccessActiveItem,
  isEditMode = false,
}) => {
  const clients = useSelector((state) => state.app.clients);
  const ingredientCategories = useSelector(
    (state) => state.app.ingredientCategories
  );

  const [isCalculated, setIsCalculated] = useState(false);

  const {
    isDownloading,
    isWaitingForResponse,
    changeWaitingForResponse,
    changeDownloading,
  } = useLoadingState();
  const { fetchData } = useFetchData({
    changeWaitingForResponse,
    changeDownloading,
  });
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();
  const {
    formData,
    errorData,
    priceData,
    errorPriceData,
    ingredientsByType,
    setNewFormData,
    clearStates,
    handleChangeForm,
    handleAddIngredient,
    handleDeleteIngredient,
    handleCalculateReceipt,
    handleChangePriceForm,
    handleSubmitReceipt,
  } = useReceiptManagement({
    setIsCalculated,
    editItem: activeItem,
    fetchData,
    setDialog,
  });

  const clearData = () => {
    setIsCalculated(false);
    setDialog();
  };

  useEffect(() => {
    if (!activeItem) {
      clearData();
    }
  }, [activeItem]);

  useEffect(() => {
    return () => {
      clearData();
    };
  }, []);

  // TODO: DODAĆ WALIDACJE DLA PRZELICZEŃ SKŁADNIKÓW NA MIN. 0.5G

  const onSubmit = async () => {
    function onSuccess() {
      clearData();
      clearStates();
      if (!!activeItem) {
        handleSuccessActiveItem();
      } else {
        setDialog(
          dialogTypes.info,
          "Recepta została utworzona i dodano ją do listy stworzonych recept."
        );
      }
    }

    if (isEditMode)
      handleSubmitReceipt(
        fetchTypes.patch,
        `${Endpoints.receiptsHistory}${activeItem.id}/`,
        onSuccess
      );
    else
      handleSubmitReceipt(
        fetchTypes.post,
        Endpoints.receiptsHistory,
        onSuccess
      );
  };

  const handleChooseItem = async (item) => {
    setDialog();

    const response = await fetchData(
      fetchTypes.get,
      `${Endpoints.receipts}${item.id}/`
    );
    if (response.success) {
      const receiptData = {
        name: response.data.name,
        description: response.data.description,
        receiptType: _.find(
          receiptTypes,
          (type) => type.value === response.data.receiptType
        ),
        ingredients: _.map(response.data.ingredients, (ingredient, index) => ({
          id: index,
          value: { ...ingredient },
          count: ingredient.count,
          dose: "",
        })),
      };
      setNewFormData(receiptData);
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  const handleClearForm = () => {
    clearData();
    clearStates();
  };

  return (
    <>
      {!activeItem && (
        <Wrapper
          isDownloading={isDownloading}
          isDialogVisible={isDialogVisible}
          dialogMessage={dialogMessage}
          handleAccept={handleClearForm}
          handleCloseDialog={setDialog}
        >
          <Dialog
            open={isDialogVisible(dialogTypes.edit)}
            handleClose={setDialog}
            title="Zapisane recepty"
            content={
              <SavedReceipts isDialog handleChooseItem={handleChooseItem} />
            }
            maxWidth="md"
          />
        </Wrapper>
      )}

      <Box display="flex" flexDirection="column" gap="10px">
        <ReceiptForm
          isEditMode={!!activeItem}
          isCalculated={isCalculated}
          isWaiting={isWaitingForResponse}
          formData={formData}
          errorData={errorData}
          clients={clients}
          ingredientsData={ingredientsByType}
          ingredientCategories={ingredientCategories}
          setDialog={setDialog}
          handleChangeForm={handleChangeForm}
          handleAddIngredient={handleAddIngredient}
          handleDeleteIngredient={handleDeleteIngredient}
        />

        <ReceiptPricesForm
          isCalculated={isCalculated}
          isEditMode={!!activeItem}
          isWaiting={isWaitingForResponse}
          formData={formData}
          priceData={priceData}
          errorPriceData={errorPriceData}
          handleChangePriceForm={handleChangePriceForm}
        />

        {errorData["form"].value && (
          <Alert severity="error">
            {errorData.form.message || "Podane dane są błędne!"}
          </Alert>
        )}

        {errorPriceData["form"].value && (
          <Alert severity="error">
            {errorPriceData.form.message || "Podane dane są błędne!"}
          </Alert>
        )}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          padding="16px"
          gap="10px"
        >
          {isCalculated ? (
            <>
              <Button onClick={() => setIsCalculated(false)}>
                Edytuj receptę
              </Button>
              <Button onClick={onSubmit} variant="contained">
                Zapisz receptę
              </Button>
            </>
          ) : (
            <Button onClick={handleCalculateReceipt} variant="contained">
              Przelicz receptę
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default NewReceipt;
