import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Table, Wrapper } from "../components";
import { sentReceiptColumns } from "../utils/columns";
import { onSetCopiedItem } from "../stores/appStore";
import { useDialogState, useFetchData, useLoadingState } from "../utils/hooks";
import { Endpoints } from "../utils/endpoints";
import { dialogTypes, fetchTypes } from "../utils/enums";

const ClientContent = ({ data, shouldDownload }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clientData, setClientData] = useState([]);

  const { isDownloading, changeDownloading } = useLoadingState();
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();
  const { fetchData } = useFetchData({ changeDownloading });

  useEffect(() => {
    async function getInitialData() {
      const response = await fetchData(
        fetchTypes.get,
        `${Endpoints.clientHistory}${data.id}`
      );
      if (response.success) {
        setClientData(response.data);
      } else {
        setDialog(dialogTypes.error, response.message);
      }
    }

    if (shouldDownload) getInitialData();
  }, [shouldDownload]);

  useEffect(() => {
    return () => {
      setClientData([]);
    };
  }, []);

  const handleCopyReceipt = async (item) => {
    const response = await fetchData(
      fetchTypes.get,
      `${Endpoints.receiptsHistory}${item.id}/`
    );
    if (response.success) {
      dispatch(onSetCopiedItem(response.data));
      navigate("/nowa-recepta");
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  return (
    <Wrapper
      isDownloading={isDownloading}
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={setDialog}
    >
      <Table
        columns={sentReceiptColumns}
        data={clientData}
        title="Wysłane recepty"
        checking={false}
        expanding={true}
        expandContent="receipt"
        initialOrderBy="dateCreated"
        onCopyItem={handleCopyReceipt}
      />
    </Wrapper>
  );
};

export default ClientContent;
