import { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilters,
  resetFilters,
  initialFilters,
} from "../../stores/filtersStore";

export const useGridManagement = ({
  rows,
  initialOrder = initialFilters.order,
  initialOrderBy = initialFilters.orderBy,
}) => {
  const dispatch = useDispatch();
  const {
    searchValue,
    order,
    orderBy,
    selected,
    selectedIds,
    expanded,
    page,
    rowsPerPage,
  } = useSelector((state) => state.filters.data);

  useEffect(() => {
    if (order !== initialOrder || orderBy !== initialOrderBy) {
      dispatch(setFilters({ order: initialOrder, orderBy: initialOrderBy }));
    }

    return () => {
      if (
        searchValue !== initialFilters.searchValue ||
        order !== initialFilters.order ||
        orderBy !== initialFilters.orderBy ||
        selected !== initialFilters.selected ||
        selectedIds !== initialFilters.selectedIds ||
        expanded !== initialFilters.expanded ||
        page !== initialFilters.page ||
        rowsPerPage !== initialFilters.rowsPerPage
      )
        dispatch(resetFilters());
    };
  }, []);

  const handleChangeSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(
      setFilters({ order: isAsc ? "desc" : "asc", orderBy: property, page: 0 })
    );
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedIds = rows.map((n) => n.id);
      dispatch(setFilters({ selected: rows, selectedIds: newSelectedIds }));
    } else {
      dispatch(setFilters({ selected: [], selectedIds: [] }));
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelectedIds = [];

    if (selectedIndex === -1) {
      newSelectedIds = newSelectedIds.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelectedIds = newSelectedIds.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelectedIds = newSelectedIds.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedIds = newSelectedIds.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }

    dispatch(
      setFilters({
        selected: _.map(newSelectedIds, (newSelectedId) =>
          _.find(rows, (row) => row.id === newSelectedId)
        ),
        selectedIds: newSelectedIds,
      })
    );
  };

  const handleExpandRow = (event, id) => {
    const expandedIndex = expanded.indexOf(id);
    let newExpanded = [];

    if (expandedIndex === -1) {
      newExpanded = newExpanded.concat(expanded, id);
    } else if (expandedIndex === 0) {
      newExpanded = newExpanded.concat(expanded.slice(1));
    } else if (expandedIndex === expanded.length - 1) {
      newExpanded = newExpanded.concat(expanded.slice(0, -1));
    } else if (expandedIndex > 0) {
      newExpanded = newExpanded.concat(
        expanded.slice(0, expandedIndex),
        expanded.slice(expandedIndex + 1)
      );
    }

    dispatch(setFilters({ expanded: newExpanded }));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setFilters({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      setFilters({ page: 0, rowsPerPage: parseInt(event.target.value, 10) })
    );
  };

  const handleChangeSearchValue = (e) => {
    dispatch(setFilters({ searchValue: e.target.value }));
  };

  return {
    handleChangeSort,
    handleSelectAllClick,
    handleClick,
    handleExpandRow,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSearchValue,
  };
};
