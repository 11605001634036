import { contentRegex, emailRegex } from "../../../utils/validators";

export const initialFormData = {
  lastName: "",
  firstName: "",
  fullName: "",
  email: "",
  mobile: "",
  phone: "",
  description: "",
  street: "",
  city: "",
  zipCode: "",
};

export const initialErrorData = {
  lastName: { value: false, validate: contentRegex, required: true },
  firstName: { value: false, validate: contentRegex, required: true },
  fullName: { value: false, validate: contentRegex, required: true },
  email: { value: false, validate: emailRegex },
  mobile: { value: false, validate: contentRegex },
  phone: { value: false, validate: contentRegex },
  description: { value: false, validate: contentRegex },
  street: { value: false, validate: contentRegex, required: true },
  city: { value: false, validate: contentRegex, required: true },
  zipCode: { value: false, validate: contentRegex, required: true },
  form: { value: false, message: "" },
};
