import React from "react";
import _ from "lodash";
import { alpha } from "@mui/material/styles";
import {
  Toolbar,
  Typography,
  TextField,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import { FilterList, Delete } from "@mui/icons-material";

const TableToolbar = ({
  numSelected,
  title,
  searchValue,
  changeSearchValue,
  toolbarActions,
  clear,
}) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 " }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} wybranych
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 " }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {!clear ? title : ""}
        </Typography>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Delete />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterList />
          </IconButton>
        </Tooltip>
      )} */}

      <Box margin={"0 10px"}>{toolbarActions}</Box>

      <TextField
        id="search"
        label="Wyszukaj"
        value={searchValue}
        onChange={changeSearchValue}
        variant="outlined"
        size="small"
      />
    </Toolbar>
  );
};

export default TableToolbar;
