import axios from "axios";
import { Endpoints } from "../endpoints";
import { getErrorResponse } from "./app";
import { privatePages } from "../navItems";
import _ from "lodash";
import { groups } from "../enums/auth";

export const inviteUser = async (token, data) => {
  const headers = { Authorization: `Token ${token}` };
  return axios
    .post(
      Endpoints.invite,
      { emails: [data] },
      {
        headers,
      }
    )
    .then((response) => {
      return { success: true };
    })
    .catch((error) => {
      return {
        success: false,
        message:
          error.response?.data?.nonFieldErrors?.[0] ||
          "Wystąpił problem podczas tworzenia zaproszenia.",
      };
    });
};

export const updateUserData = async (token, data) => {
  const headers = { Authorization: `Token ${token}` };

  return axios
    .patch(
      Endpoints.user,
      { ...data },
      {
        headers,
      }
    )
    .then((response) => {
      return { success: true, data: response.data };
    })
    .catch((error) => {
      return {
        success: false,
        data: null,
        message:
          error.response?.data?.nonFieldErrors?.[0] ||
          "Wystąpił problem podczas zmiany danych.",
      };
    });
};

export const changePassword = async (token, data) => {
  const headers = { Authorization: `Token ${token}` };

  return axios
    .post(
      Endpoints.passwordChange,
      { ...data },
      {
        headers,
      }
    )
    .then((response) => {
      return { success: true };
    })
    .catch((error) => {
      return {
        success: false,
        message: getErrorResponse(error?.response?.data?.errors),
      };
    });
};

export const getAvailablePrivatePages = (user) => {
  if (user?.isSuperuser || user?.isStaff) return privatePages;

  return _.filter(
    privatePages,
    (page) =>
      !page.group ||
      page.group === user?.group ||
      (!user?.group && page.group === groups.user)
  );
};

export const isPrivatePageAvailable = (user, path) =>
  _.some(getAvailablePrivatePages(user), (page) => page.path.includes(path));
