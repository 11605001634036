import { Divider, Grid, Typography } from "@mui/material";
import React from "react";

const FormContainer = ({ title, children, ...rest }) => {
  return (
    <>
      {title && (
        <Divider textAlign="left">
          <Typography variant="button">{title}</Typography>
        </Divider>
      )}

      {!!children && (
        <Grid
          container
          spacing={2}
          sx={(4, { padding: "5px 16px 15px 16px" })}
          {...rest}
        >
          {children}
        </Grid>
      )}
    </>
  );
};

export default FormContainer;
