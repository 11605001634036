import Moment from "react-moment";

export const savedReceiptColumns = [
  {
    id: "name",
    numeric: false,
    label: "Nazwa",
    width: "40%",
  },
  {
    id: "description",
    numeric: false,
    label: "Opis",
    width: "50%",
  },
  {
    id: "receiptTypeName",
    numeric: false,
    label: "Rodzaj",
  },
];

export const sentReceiptColumns = [
  {
    id: "barcodeText",
    numeric: false,
    label: "Numer zamówienia",
  },
  {
    id: "name",
    numeric: false,
    label: "Nazwa",
    width: "20%",
  },
  {
    id: "receiptTypeName",
    numeric: false,
    label: "Rodzaj",
  },
  {
    id: "clientName",
    numeric: false,
    label: "Klient i adres wysyłki",
    width: "50%",
  },
  {
    id: "dateSent",
    numeric: false,
    label: "Do wysyłki",
    valueGetter: (value) => <Moment format="HH:mm DD/MM/YYYY">{value}</Moment>,
  },
  {
    id: "dateSentToClient",
    numeric: false,
    label: "Do klienta",
    valueGetter: (value) =>
      !!value ? (
        <Moment format="HH:mm DD/MM/YYYY">{value}</Moment>
      ) : (
        "Niewysłane"
      ),
  },
  {
    id: "sppp",
    numeric: true,
    label: "Kwota z VAT",
    valueGetter: (value) => `${value.toFixed(2)} zł`,
  },
];

export const shippingReceiptColumns = [
  {
    id: "barcodeText",
    numeric: false,
    label: "Numer zamówienia",
  },
  {
    id: "receiptTypeName",
    numeric: false,
    label: "Rodzaj",
  },
  {
    id: "clientName",
    numeric: false,
    label: "Klient i adres wysyłki",
    width: "50%",
  },
  {
    id: "dateSent",
    numeric: false,
    label: "Data",
    valueGetter: (value) => <Moment format="HH:mm DD/MM/YYYY">{value}</Moment>,
  },
];

export const receiptColumns = [
  {
    id: "name",
    numeric: false,
    label: "Nazwa",
    width: "10%",
  },
  {
    id: "receiptTypeName",
    numeric: false,
    label: "Rodzaj",
  },
  {
    id: "clientName",
    numeric: false,
    label: "Klient i adres wysyłki",
    width: "50%",
  },
  {
    id: "sppp",
    numeric: true,
    label: "Kwota z VAT",
    valueGetter: (value) => `${value.toFixed(2)} zł`,
  },
];

export const createdReceiptColumns = [
  {
    id: "barcodeText",
    numeric: false,
    label: "Numer zamówienia",
  },
  {
    id: "name",
    numeric: false,
    label: "Nazwa",
    width: "10%",
  },
  {
    id: "receiptTypeName",
    numeric: false,
    label: "Rodzaj",
  },
  {
    id: "clientName",
    numeric: false,
    label: "Klient i adres wysyłki",
    width: "45%",
  },
  {
    id: "dateCreated",
    numeric: false,
    label: "Utworzono",
    valueGetter: (value) => <Moment format="HH:mm DD/MM/YYYY">{value}</Moment>,
  },
  {
    id: "sppp",
    numeric: true,
    label: "Kwota z VAT",
    valueGetter: (value) => `${value.toFixed(2)} zł`,
  },
];
