import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Grid, Wrapper } from "../components";
import { onSetCopiedItem } from "../stores/appStore";
import { useLoadingState, useDialogState, useFetchData } from "../utils/hooks";
import { dialogTypes, fetchTypes } from "../utils/enums";
import { Endpoints } from "../utils/endpoints";
import { savedReceiptColumns } from "../utils/columns";

const FavoriteReceipts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const { isDownloading, changeDownloading } = useLoadingState();
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();
  const { fetchData } = useFetchData({ changeDownloading });

  useEffect(() => {
    setActiveItem(null);
  }, []);

  const handleCloseDialog = () => {
    setDialog();
    setActiveItem(null);
  };

  const handleSetShouldRefresh = (value) => {
    setShouldRefresh(!!value);
  };

  const handleRemoveFavoriteReceipt = async () => {
    const response = await fetchData(fetchTypes.post, Endpoints.favorites, {
      id: [activeItem.id],
      delete: true,
    });

    if (response.success) {
      handleSetShouldRefresh(true);
      setDialog(dialogTypes.info, `Recepta została usunięta z ulubionych.`);
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  const mapFavoriteReceipt = (item) => ({
    dailyDose: item.dailyDose,
    description: item.description,
    doseFreq: item.doseFreq,
    dosePeriod: item.dosePeriod,
    id: item.id,
    name: item.name,
    notesForClient: item.notesForClient,
    notesForEmployee: item.notesForEmployee,
    receiptType: item.receiptType,
    receiptTypeName: item.receiptTypeName,
    ingredients: item.ingredients,
    doublePrepare: !!item.doublePrepare,
  });

  const handleMapFavoriteReceipts = (data) =>
    _.map(data, (receipt) => mapFavoriteReceipt(receipt));

  const handleCopyReceipt = async (item) => {
    const response = await fetchData(
      fetchTypes.get,
      `${Endpoints.receiptsHistory}${item.id}/`
    );
    if (response.success) {
      dispatch(onSetCopiedItem(mapFavoriteReceipt(response.data)));
      setTimeout(() => {
        navigate("/nowa-recepta");
      }, 500);
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  const handleDeleteItem = (item) => {
    setActiveItem(item);
    setDialog(
      dialogTypes.remove,
      `Czy na pewno chcesz usunąć receptę z ulubionych?`
    );
  };

  return (
    <Wrapper
      isDownloading={isDownloading}
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={handleCloseDialog}
      handleDelete={handleRemoveFavoriteReceipt}
    >
      <Grid
        columns={savedReceiptColumns}
        url={Endpoints.favorites}
        title="Polubione recepty"
        checking={false}
        expanding={true}
        expandContent="receipt"
        onCopyItem={handleCopyReceipt}
        onDeleteItem={handleDeleteItem}
        shouldRefresh={shouldRefresh}
        setRefresh={handleSetShouldRefresh}
        mapper={handleMapFavoriteReceipts}
      />
    </Wrapper>
  );
};

export default FavoriteReceipts;
