import axios from "axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchTypes } from "../enums";
import { getErrorResponse } from "../helpers/app";

export const useFetchData = ({
  changeDownloading,
  changeWaitingForResponse,
}) => {
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    return () => {};
  }, []);

  const fetchData = async (type, url, body, withToken = true) => {
    if (!!changeDownloading) changeDownloading(true);
    if (!!changeWaitingForResponse) changeWaitingForResponse(true);
    const currentToken = token || Cookies.get("token");

    const headers = { Authorization: `Token ${currentToken}` };
    let response;
    switch (type) {
      case fetchTypes.get:
        response = await axios
          .get(url, withToken ? { headers } : undefined)
          .then((res) => {
            return { success: true, data: res.data, message: "" };
          })
          .catch((error) => {
            return {
              success: false,
              data: null,
              message: getErrorResponse(error?.response?.data?.errors),
            };
          });
        break;
      case fetchTypes.post:
        response = await axios
          .post(url, body, withToken ? { headers } : undefined)
          .then((res) => {
            return { success: true, data: res.data, message: "" };
          })
          .catch((error) => {
            return {
              success: false,
              data: null,
              message: getErrorResponse(
                error?.response?.data?.errors ?? error?.response?.data
              ),
            };
          });
        break;
      case fetchTypes.patch:
        response = await axios
          .patch(url, body, withToken ? { headers } : undefined)
          .then((res) => {
            return { success: true, data: res.data, message: "" };
          })
          .catch((error) => {
            return {
              success: false,
              data: null,
              message: getErrorResponse(error?.response?.data?.errors),
            };
          });
        break;
      case fetchTypes.delete:
        response = await axios
          .delete(url, withToken ? { headers } : undefined)
          .then((res) => {
            return { success: true, data: res.data, message: "" };
          })
          .catch((error) => {
            return {
              success: false,
              data: null,
              message: getErrorResponse(error?.response?.data?.errors),
            };
          });
        break;
      default:
        break;
    }

    if (!!changeDownloading) changeDownloading(false);
    if (!!changeWaitingForResponse) changeWaitingForResponse(false);
    return response;
  };

  return { fetchData };
};
