import { Box, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Wrapper } from "../../components";
import { onSetDoctors } from "../../stores/appStore";
import { Endpoints } from "../../utils/endpoints";
import { dialogTypes, fetchTypes } from "../../utils/enums";
import {
  useDialogState,
  useFetchData,
  useFormState,
  useLoadingState,
} from "../../utils/hooks";
import {
  initialAdminFormData,
  initialFormData,
  initialErrorData,
  SearchBar,
} from "./utlis";
import moment from "moment";
import _ from "lodash";
import { LineChart } from "./components";

const columns = [
  {
    id: "code",
    numeric: false,
    label: "Kod",
  },
  {
    id: "name",
    numeric: false,
    label: "Nazwa",
  },
  {
    id: "used",
    numeric: true,
    label: "Zużyto [g]",
    valueGetter: (value) => value.toFixed(2),
  },
  {
    id: "buyingPrice",
    numeric: true,
    label: "Zakup [zł]",
    valueGetter: (value) => value.toFixed(2),
  },
  {
    id: "price",
    numeric: true,
    label: "Przychód [zł]",
    valueGetter: (value) => value.toFixed(2),
  },
  {
    id: "priceDiff",
    numeric: true,
    label: "Dochód [zł]",
    valueGetter: (value) => value.toFixed(2),
  },
];

const Statistics = () => {
  const dispatch = useDispatch();
  const date = moment();
  const doctors = useSelector((state) => state.app.doctors);
  const ingredients = useSelector((state) => state.app.ingredients);
  const user = useSelector((state) => state.auth.user);
  const isAdmin = user?.isSuperuser || user?.isStaff;
  const [data, setData] = useState(null);
  const [sumData, setSumData] = useState(null);

  const {
    isDownloading,
    changeDownloading,
    isWaitingForResponse,
    changeWaitingForResponse,
  } = useLoadingState();
  const { fetchData } = useFetchData({
    changeDownloading,
    changeWaitingForResponse,
  });
  const { formData, errorData, changeFormData, onSubmitForm, setFormData } =
    useFormState({
      initialFormData: isAdmin ? initialAdminFormData : initialFormData,
      initialErrorData,
      fetchData,
    });
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();

  useEffect(() => {
    async function getInitialData() {
      const response = await fetchData(fetchTypes.get, Endpoints.doctors);
      if (response.success) {
        dispatch(onSetDoctors(response.data));
      } else {
        setDialog(dialogTypes.error, response.message);
      }
    }

    if (isAdmin && !doctors.length) getInitialData();

    const newFormData = {
      from: date.startOf("month").format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
    };

    if (!isAdmin) _.set(newFormData, "doctor", null);

    setFormData((prevState) => ({
      ...prevState,
      ...newFormData,
    }));

    return () => {
      setData(null);
      setSumData(null);
    };
  }, [isAdmin]);

  const onSubmit = async () => {
    function onSuccess(response) {
      setData({
        ...response.data,
      });
      const data = response.data.tableData;
      let sumUsed = 0,
        sumBuyingPrice = 0,
        sumPrice = 0,
        sumPriceDiff = 0;

      data.forEach((item) => {
        sumUsed += item.used;
        sumBuyingPrice += item.buyingPrice;
        sumPrice += item.price;
        sumPriceDiff += item.priceDiff;
      });

      setSumData({ sumUsed, sumBuyingPrice, sumPrice, sumPriceDiff });
    }

    let url = `${Endpoints.analytics}?date_from=${formData.from}&date_to=${formData.to}`;
    if (!formData.doctor) url += `&doctor_id=${user.id}`;
    if (!!formData?.doctor?.pk) url += `&doctor_id=${formData.doctor.pk}`;
    if (!!formData?.type?.value)
      url += `&ingredient_letter=${formData.type.value}`;
    if (!!formData?.ingredient?.code)
      url += `&ingredient_code=${formData.ingredient.code}`;
    onSubmitForm(fetchTypes.get, url, onSuccess);
  };

  if (!isAdmin) return <div>Moduł w trakcie budowy...</div>;

  const changeData = (e, obj) => {
    changeFormData(e, obj);
    setData(null);
    setSumData(null);
  };

  return (
    <Wrapper
      isDownloading={isDownloading}
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={setDialog}
    >
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", pl: "16px" }}>
          <Box
            minHeight={64}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Typography sx={{ flex: "1 1" }} variant="h6" component="div">
              Statystyki
            </Typography>
          </Box>
          <SearchBar
            isAdmin={isAdmin}
            formData={formData}
            doctors={doctors}
            ingredients={ingredients}
            changeFormData={changeData}
            isWaitingForResponse={isWaitingForResponse}
            errorData={errorData}
            onSubmit={onSubmit}
          />
        </Paper>
      </Box>

      {!!data && (
        <Box display="flex" gap="3rem" flexDirection="column" marginTop="2rem">
          <LineChart data={data.lineIngredients} />
          <Table
            columns={columns}
            data={data.tableData}
            title="Składniki"
            checking={false}
            expanding={false}
            footer={
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "1rem",
                  padding: "1rem",
                }}
              >
                <span
                  style={{ textTransform: "uppercase", fontWeight: "bold" }}
                >
                  Podsumowanie
                </span>
                <span>
                  Zużyto [g]: <b>{sumData.sumUsed.toFixed(2)}</b>
                </span>
                <span>
                  Zakup [zł]: <b>{sumData.sumBuyingPrice.toFixed(2)}</b>
                </span>
                <span>
                  Przychód [zł]: <b>{sumData.sumPrice.toFixed(2)}</b>
                </span>
                <span>
                  Dochód [zł]: <b>{sumData.sumPriceDiff.toFixed(2)}</b>
                </span>
              </div>
            }
          />
        </Box>
      )}
    </Wrapper>
  );
};

export default Statistics;
