import React from "react";
import _ from "lodash";
import {
  Box,
  TableCell,
  TableRow as MuiTableRow,
  Checkbox,
  IconButton,
  Collapse,
  Tooltip,
} from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Delete,
  Edit,
  Check,
  ContentCopy,
} from "@mui/icons-material";
import {
  OrderContent,
  ReceiptContent,
  ClientContent,
  ExpandTable,
} from "../../";

const TableRow = ({
  type,
  row,
  columns,
  index,
  isItemSelected,
  isItemExpanded,
  labelId,
  checking,
  expanding,
  expandContent,
  expandColumns,
  handleExpandRow,
  onSetActiveItem,
  handleClick,
  isAccept,
  isEdit,
  isDelete,
  isCopy,
  deleteTitle,
  deleteIcon,
}) => {
  const handleCheck = async (e) => {
    if (checking) handleClick(e, row.id);
  };

  const handleExpand = (e) => {
    if (expanding) {
      handleExpandRow(e, row.id);
      onSetActiveItem(row);
    }
  };

  return (
    <>
      <MuiTableRow
        hover
        onClick={handleCheck}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected || isItemExpanded}
      >
        {checking && (
          <TableCell padding="checkbox" size="small">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": labelId,
              }}
            />
          </TableCell>
        )}

        {expanding && (
          <TableCell size="small">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleExpand}
            >
              {isItemExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        )}

        {_.map(columns, (column) => (
          <TableCell
            key={`${row.id}-${index}-${column.id}`}
            component="td"
            align={column.numeric ? "right" : "left"}
            scope="row"
            size="small"
          >
            <Box
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineClamp: 2,
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
            >
              {column.valueGetter
                ? column.valueGetter(row[column.id])
                : row[column.id]}
            </Box>
          </TableCell>
        ))}

        {isAccept || isDelete || isEdit || isCopy ? (
          <TableCell component="td" align={"center"} scope="row" size="small">
            {isAccept && (
              <Tooltip title="Potwierdź">
                <IconButton onClick={() => onSetActiveItem(row, "accept")}>
                  <Check fontSize="small" color="success" />
                </IconButton>
              </Tooltip>
            )}
            {isEdit && (
              <Tooltip title="Edytuj">
                <IconButton onClick={() => onSetActiveItem(row, "edit")}>
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {isCopy && (
              <Tooltip title="Kopiuj">
                <IconButton onClick={() => onSetActiveItem(row, "copy")}>
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {isDelete && (
              <Tooltip title={!!deleteTitle ? deleteTitle : "Usuń"}>
                <IconButton onClick={() => onSetActiveItem(row, "delete")}>
                  {!!deleteIcon ? (
                    deleteIcon
                  ) : (
                    <Delete fontSize="small" color="error" />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        ) : null}
      </MuiTableRow>

      <MuiTableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: "unset" }}
          colSpan={12}
          size="small"
        >
          <Collapse in={isItemExpanded}>
            <Box sx={{ margin: "1rem 2rem" }}>
              {expandContent === "receipt" ? (
                <ReceiptContent
                  data={row}
                  type={type}
                  shouldDownload={isItemExpanded}
                />
              ) : expandContent === "order" ? (
                <OrderContent data={row} shouldDownload={isItemExpanded} />
              ) : expandContent === "client" ? (
                <ClientContent
                  data={row}
                  type={type}
                  shouldDownload={isItemExpanded}
                />
              ) : (
                <ExpandTable
                  title={expandContent}
                  columns={expandColumns}
                  data={row}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </MuiTableRow>
    </>
  );
};

export default TableRow;
