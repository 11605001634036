import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  Box,
  Table,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
import { GridBody, GridHeader, GridToolbar } from "./components";
import {
  useDialogState,
  useFetchData,
  useGridManagement,
  useLoadingState,
} from "../../utils/hooks";
import { Wrapper } from "../";
import { dialogTypes, fetchTypes } from "../../utils/enums";
import { useDispatch, useSelector } from "react-redux";
import {
  onCollapse,
  resetFilters,
  setFilters,
} from "../../stores/filtersStore";

const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const getUrl = ({ url, searchValue, order, orderBy, page, rowsPerPage }) => {
  if (!url) return;
  let newUrl = url;

  if (!_.includes(url, "?")) newUrl += "?";
  if (newUrl.slice(-1) !== "?") newUrl += "&";
  newUrl += `page=${+page + 1}`;
  newUrl += `&size=${rowsPerPage}`;
  if (!!orderBy) newUrl += `&ordering=${order === "desc" ? "-" : ""}${orderBy}`;
  if (!!searchValue) newUrl += `&search_text=${searchValue}`;

  return newUrl;
};

const Grid = ({
  url,
  columns,
  initialOrder,
  initialOrderBy,
  type,
  title,
  checking = true,
  toolbarActions,
  expanding = false,
  expandContent,
  onAcceptItem,
  onEditItem,
  onDeleteItem,
  onCopyItem,
  onFavoriteItem,
  onShippingItem,
  deleteIcon,
  deleteTitle,
  clear,
  shouldRefresh,
  setRefresh,
  mapper,
  disabled,
  onRowClick,
}) => {
  const dispatch = useDispatch();
  const firstUpdate = useRef(true);
  const prevUrl = usePrevious(url);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const {
    searchValue,
    order,
    orderBy,
    selectedIds,
    expanded,
    page,
    rowsPerPage,
  } = useSelector((state) => state.filters.data);

  const {
    handleChangeSort,
    handleSelectAllClick,
    handleClick,
    handleExpandRow,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChangeSearchValue,
  } = useGridManagement({ rows, initialOrder, initialOrderBy });
  const { isWaitingForResponse, changeWaitingForResponse } = useLoadingState();
  const { fetchData } = useFetchData({
    changeWaitingForResponse,
  });
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();

  const getData = async () => {
    const response = await fetchData(
      fetchTypes.get,
      getUrl({ url, searchValue, order, orderBy, page, rowsPerPage })
    );
    if (response.success) {
      const data = response.data;
      setCount(data.total?.count || data.count);
      if (!!mapper) setRows(mapper(data.results));
      else setRows(data.results);
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  useEffect(() => {
    if (shouldRefresh) {
      setRefresh();
      getData();
    }
  }, [shouldRefresh]);

  useEffect(() => {
    getData();
  }, [order, orderBy, page, rowsPerPage]);

  useEffect(() => {
    if (prevUrl !== url && !!prevUrl) {
      dispatch(resetFilters());
      getData();
    }
  }, [url]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!firstUpdate.current) {
        if (page === 0) getData();
        else dispatch(setFilters({ page: 0 }));
      }
      if (firstUpdate.current) firstUpdate.current = false;
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  useEffect(() => {
    return () => {
      setRows([]);
      setCount(0);
    };
  }, []);

  const handleSetActiveItem = (item, type) => {
    switch (type) {
      case "accept":
        onAcceptItem(item);
        break;
      case "delete":
        onDeleteItem(item);
        break;
      case "edit":
        onEditItem(item);
        dispatch(onCollapse(item.id));
        break;
      case "copy":
        onCopyItem(item);
        break;
      case "like":
        onFavoriteItem(item);
        break;
      case "shipping":
        onShippingItem(item);
        break;
      default:
        console.log("Error with: ", type, item);
        break;
    }
  };

  return (
    <Wrapper
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={setDialog}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            mb: 2,
            display: "flex",
            flexDirection: "column",
          }}
          elevation={clear ? 0 : 1}
        >
          <GridToolbar
            numSelected={selectedIds.length}
            title={title}
            searchValue={searchValue}
            changeSearchValue={handleChangeSearchValue}
            toolbarActions={toolbarActions}
            clear={clear}
          />
          <TableContainer
            sx={{
              height: "100%",
              maxHeight: "calc(100vh - 2rem - 205px)",
            }}
          >
            <Table sx={{ minWidth: 1200 }} size={"medium"} stickyHeader>
              <GridHeader
                numSelected={selectedIds.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleChangeSort}
                rowCount={rows.length}
                columns={columns}
                checking={checking}
                expanding={expanding}
                disabled={isWaitingForResponse}
                isAccept={!!onAcceptItem}
                isEdit={!!onEditItem}
                isDelete={!!onDeleteItem}
                isCopy={!!onCopyItem}
                isFavorite={!!onFavoriteItem}
                isShipping={!!onShippingItem}
              />
              <GridBody
                type={type}
                columns={columns}
                checking={checking}
                expanding={expanding}
                rows={rows}
                selected={selectedIds}
                expanded={expanded}
                handleClick={handleClick}
                handleExpandRow={handleExpandRow}
                expandContent={expandContent}
                loading={isWaitingForResponse}
                isAccept={!!onAcceptItem}
                isEdit={!!onEditItem}
                isDelete={!!onDeleteItem}
                isCopy={!!onCopyItem}
                isFavorite={!!onFavoriteItem}
                isShipping={!!onShippingItem}
                deleteTitle={deleteTitle}
                deleteIcon={deleteIcon}
                onSetActiveItem={handleSetActiveItem}
                disabled={disabled}
                onRowClick={onRowClick}
              />
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ marginTop: "auto", minHeight: "52px", height: "auto" }}
            labelRowsPerPage="Wierszy:"
          />
        </Paper>
      </Box>
    </Wrapper>
  );
};

export default Grid;
